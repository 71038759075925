define(['lodash', 'santa-components', 'coreUtils', 'wixappsCore'], function (_, santaComponents, /** wixappsCore */ coreUtils, wixapps) {
    'use strict';

    const stylesCache = new WeakMap();

    const addStyleIfNeeded = wixapps.styleCollector.addStyleIfNeeded;

    function getDescriptorStyles(descriptor, getStyleData) {
        if (stylesCache.has(descriptor)) {
            return stylesCache.get(descriptor);
        }

        const styles = {};
        _.forEach(descriptor.views, function (viewDef) {
            wixapps.styleCollector.collectViewStyles(viewDef, getStyleData, styles);
        });

        _.forEach(descriptor.customizations, function (rule) {
            const styleId = rule.key === 'comp.style' && rule.value;
            const skinName = rule.key === 'comp.skin' && rule.value;
            addStyleIfNeeded(styleId, skinName, getStyleData, styles);
        });

        stylesCache.set(descriptor, styles);

        return styles;
    }

    const STATIC_SKINS = [
        'wixapps.integration.skins.ecommerce.options.TextOptionSkin',
        'wixapps.integration.skins.ecommerce.options.ColorOptionSkin',
        'wixapps.integration.skins.ecommerce.options.OptionsListInputSkin',
        'wixapps.integration.skins.ecommerce.options.InfoTipSkin',
        'ecommerce.skins.mcom.MobileSelectOptionsListSkin',
        'ecommerce.skins.mcom.MobileTextOptionSkin',
        'ecommerce.skins.mcom.MobileColorOptionSkin',
        'wysiwyg.viewer.skins.gallerymatrix.BlogMatrixGallery',
        'skins.viewer.gallery.BlogSlideShow'
    ];
    const STATIC_STYLES = _.reduce(STATIC_SKINS, (acc, skinName) => _.set(acc, coreUtils.santaTypes.shortenStyleId(skinName), {skinName}), {});

    function getCustomizationsStyles(compData, getStyleData) {
        if (stylesCache.has(compData)) {
            return stylesCache.get(compData);
        }

        const styles = {};

        _.forEach(compData.appLogicCustomizations, function (rule) {
            const styleId = rule.key === 'comp.style' && rule.value;
            const skinName = rule.key === 'comp.skin' && rule.value;
            addStyleIfNeeded(styleId, skinName, getStyleData, styles);
        });

        stylesCache.set(compData, styles);

        return styles;
    }

    function addAppPartStyles({compData, getStyleData, descriptor, loadedStyles = {}}) {
        if (!descriptor) {
            return {};
        }

        const partData = compData.appPartName ? compData : compData.dataItemRef;
        const descriptorStyles = getDescriptorStyles(descriptor, getStyleData);
        const customizationsStyles = getCustomizationsStyles(partData, getStyleData);

        _.assign(loadedStyles, descriptorStyles, customizationsStyles, STATIC_STYLES);
        wixapps.styleCollector.addDefaultStyles(getStyleData, loadedStyles);

        return loadedStyles;
    }

    addAppPartStyles.cssTypes = {
        descriptor: wixapps.CoreSantaTypes.descriptor,
        compData: santaComponents.santaTypesDefinitions.Component.compData,
        getStyleData: santaComponents.santaTypesDefinitions.Component.getStyleData
    };

    coreUtils.styleCollector.registerClassBasedStyleCollector('wixapps.integration.components.AppPart', function (structureInfo, getStyleData, siteData, loadedStyles = {}) {
        //This code is used for garbage collection in DS
        const compData = structureInfo.dataItem;

        const partData = compData.appPartName ? compData : compData.dataItemRef;
        const customizationsStyles = getCustomizationsStyles(partData, getStyleData);
        _.assign(loadedStyles, customizationsStyles);
    });

    return addAppPartStyles;
});
