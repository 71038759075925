define([
    'lodash',
    'coreUtils'
], function (_, coreUtils) {
    'use strict';

    function translate(lang, key) {
        const valueInSelectedLang = coreUtils.translationsLoader.isLoaded(lang) && coreUtils.translationsLoader.getTranslation('blogTranslations', lang, key);
        const valueInDefaultLang = coreUtils.translationsLoader.getTranslation('blogTranslations', 'en', key);
        return valueInSelectedLang || valueInDefaultLang || key;
    }

    return {
        translate
    };
});
