define(['skins', 'santa-components', 'coreUtils', 'componentsCore', 'components'], function (skinsPackage, santaComponents, coreUtils, componentsCore, components) {
    'use strict';

    const toolTipId = 'toolTip';
    const infoTip = components.infoTip;

    /**
     * @class optionInput
     * @extends {skinBasedComp}
     */
    return {
        propTypes: santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(infoTip),
        mixins: [santaComponents.mixins.skinBasedComp(skinsPackage.skinsMap), componentsCore.mixins.createChildComponentMixin],

        getInitialState() {
            return this.getCssState(this.props);
        },

        componentWillReceiveProps(props) {
            this.setState(this.getCssState(props));
        },

        getCssState(props) {
            return {
                '$enabledState': props.compData.disabled ? 'disabled' : 'enabled',
                '$selectState': props.selected ? 'selected' : 'unselected'
            };
        },

        onMouseEnter() {
            this.refs[toolTipId].showToolTip({}, {source: this});
        },

        onMouseLeave() {
            this.refs[toolTipId].closeToolTip();
        },

        createInfoTipChildComponent() {
            return this.createChildComponent({
                content: this.props.compData.description,
                id: toolTipId
            },
            'wysiwyg.common.components.InfoTip',
            { //TODO: this should be in skin exports
                skin: 'wixapps.integration.skins.ecommerce.options.InfoTipSkin',
                styleId: coreUtils.santaTypes.shortenStyleId('wixapps.integration.skins.ecommerce.options.InfoTipSkin')
            },
            {
                ref: toolTipId,
                className: `${this.props.styleId}tooltip`
            });
        }
    };
});
