define(['lodash', 'wixappsCore'
], function (_, /** wixappsCore */wixapps) {
    'use strict';

    const logicFactory = wixapps.logicFactory;
    const PRODUCT_CHUNK = 5;

    /**
     * @class ecom.AddToCartButtonLogic
     * @param partApi
     * @constructor
     */
    function ProductListLogic(partApi) {
        this.partApi = partApi;
        const rowsCustomization = _.find(this.getUserCustomizations(partApi.getPartData().appLogicCustomizations), {'fieldId': 'logicVars', 'key': 'rows', 'format': 'Mobile'});
        this.rowsToAdd = rowsCustomization ? parseInt(rowsCustomization.value, 10) : PRODUCT_CHUNK;
        this.rows = this.rowsToAdd;
    }

    ProductListLogic.prototype = {
        getUserCustomizations(customizations) {
            return _.map(customizations, function (customization) {
                if (customization.key === 'rows' && customization.fieldId === 'vars' && customization.format === 'Mobile') {
                    return _.defaults({fieldId: 'logicVars'}, customization);
                }
                return customization;
            });
        },
        getViewVars() {
            return {
                rows: this.rows
            };
        },

        'load-more'() {
            this.rows += this.rowsToAdd;
            this.partApi.setVar('rows', this.rows);
        }
    };

    logicFactory.register('30b4a102-7649-47d9-a60b-bfd89dcca135', ProductListLogic);
});
