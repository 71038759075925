define(['lodash', 'coreUtils', 'wixappsCore', 'wixappsClassics/ecommerce/util/ecomLogger', 'wixappsClassics/ecommerce/util/errorCodeMessageMap.json'],
    function (_, coreUtils, /** wixappsCore */wixapps, /** wixappsCore.ecomLogger */ecomLogger, errorCodeMessageMap) {
        'use strict';

        const localizer = wixapps.localizer;
        const aspectName = 'ecomDialog';
        const PACKAGE_NAME = 'ecommerce';

        function addComponent(structure, props) {
            const componentToRender = {
                structure,
                props
            };
            this._aspectSiteAPI.setAspectGlobalData(aspectName, {componentToRender});
        }

        function deleteComponent() {
            this._aspectSiteAPI.setAspectGlobalData(aspectName, {componentToRender: null});
            this._aspectSiteAPI.forceUpdate();
        }

        function createMessageCompFromParams(params) {
            const title = params.code ? errorCodeMessageMap[params.code].title : params.title;
            const description = params.code ? errorCodeMessageMap[params.code].description : params.description;

            const compId = 'batata';
            const siteData = this._aspectSiteAPI.getSiteData();
            const skin = siteData.isMobileView() ? 'wysiwyg.viewer.skins.MobileMessageViewSkin' : 'wysiwyg.viewer.skins.MessageViewSkin';
            const structure = {
                id: compId,
                componentType: 'wysiwyg.viewer.components.MessageView',
                skin,
                type: 'Component'
            };

            const localizationBundle = localizer.getLocalizationBundleForPackage(this._aspectSiteAPI.getSiteAspect('wixappsDataAspect'), PACKAGE_NAME, siteData);
            const props = {
                compProp: {
                    title: localizer.localize(title, localizationBundle),
                    description: createMessageTextForUser(localizer.localize(description, localizationBundle), params.code || ''),
                    onCloseCallback: function () {
                        deleteComponent.call(this);
                    }.bind(this)
                }
            };

            addComponent.call(this, structure, props);
        }

        function createMessageTextForUser(baseMessage, error) {
            if (baseMessage.indexOf('WOS') === baseMessage.length - 3) {
                return baseMessage + error;
            }

            const styledErr = `<span style="color: #909090; font-size: 11px">(WOS ${error})</span>`;
            return `${baseMessage} ${styledErr}`;
        }

        function onAspectsReady() {
            if (!this._aspectSiteAPI.getSiteAspect('wixappsDataAspect')) {
                return;
            }

            const siteData = this._aspectSiteAPI.getSiteData();
            const queryParams = siteData.currentUrl.query;

            if (queryParams && !_.isEmpty(queryParams.f_checkoutErrorId)) {
                const params = {
                    code: _.isArray(queryParams.f_checkoutErrorId) ? queryParams.f_checkoutErrorId[0] : queryParams.f_checkoutErrorId
                };

                createMessageCompFromParams.call(this, params);
            }

            this._aspectSiteAPI.unregisterFromAspectsReady(this.boundedOnAspectsReady);
            delete this.boundedOnAspectsReady;
        }

        /**
         *
         * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
         * @implements {core.SiteAspectInterface}
         * @constructor
         */
        function EcomDialogAspect(aspectSiteAPI) {
            /** @type core.SiteAspectsSiteAPI */
            this._aspectSiteAPI = aspectSiteAPI;
            this.boundedOnAspectsReady = onAspectsReady.bind(this);
            this._aspectSiteAPI.registerToAspectsReady(this.boundedOnAspectsReady);
            this._aspectSiteAPI.setAspectGlobalData(aspectName, {componentToRender: null});

            _.bindAll(this, _.functionsIn(this));
        }

        function handleUpgrade() {
            const siteData = this._aspectSiteAPI.getSiteData();
            ecomLogger.reportEvent(siteData, ecomLogger.events.CHECKOUT_MESSAGE_UPGRADE_BUTTON_CLICK);

            // TODO: handle unsaved sites (i.e. ecom templates in the preview)
            const baseUrl = siteData.serviceTopology.premiumServerUrl || siteData.serviceTopology.billingServerUrl;
            const urlObj = coreUtils.urlUtils.parseUrl(`${baseUrl}/wix/api/premiumStart`);
            urlObj.query = {
                referralAdditionalInfo: 'html_ECOM_CHECKOUT',
                siteGuid: siteData.getMetaSiteId()
            };

            const url = coreUtils.urlUtils.buildFullUrl(urlObj);
            window.open(url, '_blank');

            deleteComponent.call(this);
        }

        EcomDialogAspect.prototype = {

            showMessage(params) {
                createMessageCompFromParams.call(this, params);
                this._aspectSiteAPI.forceUpdate();
            },

            showCheckoutDialogForOwner(partApi, cb) {
                const compId = 'batata';
                const skin = 'ecommerce.skins.viewer.dialogs.EcomCheckoutMessageDialogSkin';
                const structure = {
                    id: compId,
                    componentType: 'ecommerce.viewer.dialogs.EcomCheckoutMessageDialog',
                    skin,
                    type: 'Component'
                };

                const siteData = this._aspectSiteAPI.getSiteData();
                const localizationBundle = localizer.getLocalizationBundleForPackage(this._aspectSiteAPI.getSiteAspect('wixappsDataAspect'), PACKAGE_NAME, siteData);
                const props = {
                    compProp: {
                        title: localizer.localize('@ECOM_CHECKOUT_MESSAGE_TITLE@', localizationBundle),
                        subtitle: localizer.localize('@ECOM_CHECKOUT_MESSAGE_SUB_TITLE@', localizationBundle),
                        description: localizer.localize('@ECOM_CHECKOUT_MESSAGE_DESCRIPTION@', localizationBundle),
                        tryButton: localizer.localize('@ECOM_CHECKOUT_MESSAGE_TRY_IT_OUT_BUTTON_TEXT@', localizationBundle),
                        upgradeButton: localizer.localize('@ECOM_CHECKOUT_MESSAGE_UPGRADE_BUTTON_TEXT@', localizationBundle),
                        onTryCallback: function () {
                            cb(partApi);
                            deleteComponent.call(this);
                        }.bind(this),
                        onUpgradeCallback: handleUpgrade.bind(this),
                        onCloseCallback: function () {
                            deleteComponent.call(this);
                        }.bind(this)
                    }
                };

                addComponent.call(this, structure, props);
                this._aspectSiteAPI.forceUpdate();
            },

            getComponentsToRender() {
                const compToRender = _.get(this._aspectSiteAPI.getAspectGlobalData(aspectName), 'componentToRender');
                return compToRender ? [compToRender] : null;
            }
        };

        return EcomDialogAspect;
    });
