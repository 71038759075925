define(['lodash', 'reactDOM', 'componentsCore'], function (_, ReactDOM, componentsCore) {
    'use strict';

    const minValue = 0;
    const maxValue = 999;

    function changeValue(delta, event, domID) {
        if (this.props.compData.value) {
            const value = parseInt(this.props.compData.value, 10) + delta;
            const inputNode = ReactDOM.findDOMNode(this.refs.inputNumberInput);

            event.target = inputNode;
            checkInput.call(this, event, domID, this.props.compData.value, String(value));
        }
    }

    function onChange(event, domID, value) {
        value = value || event.target.value;
        if (value) {
            checkInput.call(this, event, domID, this.props.compData.value, value);
        }
    }

    function checkInput(event, domID, oldValue, newValue) { // eslint-disable-line complexity
        event = _.clone(event);

        let newIntValue = Number(newValue);
        const oldIntValue = Number(oldValue);
        const isSmallerThanMin = newIntValue < this.minValue;
        const isBiggerThanMax = newIntValue > this.maxValue;

        if (newValue === '' || isNaN(newIntValue)) {
            newIntValue = oldIntValue;
            return;
        } else if (isSmallerThanMin) {
            newIntValue = this.minValue;
        } else if (isBiggerThanMax) {
            if (this.props.onInputChangedFailed) {
                event.type = 'inputChangedFailed';
                event.payload = {
                    oldValue: oldIntValue,
                    invalidValue: newIntValue,
                    maxValue: this.maxValue,
                    minValue: this.minValue
                };

                this.props.onInputChangedFailed(event, domID);
                newIntValue = this.maxValue;
            }
        }
        newValue = String(newIntValue);
        if (this.props.onInputChange && newIntValue !== oldIntValue) {
            event = _.clone(event);

            event.type = 'inputChanged';
            event.payload = newValue;
            this.props.onInputChange(event, domID);
        }
    }

    function onKeyDown(event) { // eslint-disable-line complexity
        return event.key !== 'space' &&
            (!event.shiftKey || event.keyCode >= 35 && event.keyCode <= 40) && // eslint-disable-line no-mixed-operators
            (event.key.length !== 1 || event.ctrlKey || event.metaKey || event.shiftKey || event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105); // eslint-disable-line no-mixed-operators
    }

    /**
     * @class components.NumericStepper
     * @extends {core.skinBasedComp}
     */
    return {
        displayName: 'NumericStepper',
        mixins: [componentsCore.mixins.skinBasedComp],

        getInitialState() {
            return this.getState(this.props);
        },

        getState(props) {
            return {
                '$validation': props.compProp.message ? 'invalid' : 'valid'
            };
        },

        componentWillMount() {
            this.minValue = _.isUndefined(this.props.compProp.minValue) ? minValue : Number(this.props.compProp.minValue);
            this.maxValue = _.isUndefined(this.props.compProp.maxValue) ? maxValue : Number(this.props.compProp.maxValue);
        },

        componentWillReceiveProps(props) {
            this.setState(this.getState(props));
        },

        getSkinProperties() {
            return {
                inputNumberInput: {
                    value: this.props.compData.value,
                    onChange: onChange.bind(this),
                    onKeyDown: onKeyDown.bind(this)
                },
                plus: {
                    onClick: changeValue.bind(this, 1)
                },
                minus: {
                    onClick: changeValue.bind(this, -1)
                }
            };
        }
    };
});
