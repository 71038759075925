define(['componentsCore', 'wixappsCore'], function (componentsCore, /** wixappsCore */ wixApps) {
    'use strict';

    const typesConverter = wixApps.typesConverter;
    const baseProxy = wixApps.baseProxy;

    /**
     * @class proxies.NumericStepper
     * @extends proxies.mixins.baseProxy
     */
    return {
        mixins: [baseProxy],

        renderProxy() {
            const data = this.proxyData;
            const componentType = 'wysiwyg.common.components.NumericStepper';
            const props = this.getChildCompProps(componentType);
            props.compData = typesConverter.numeric(data.value);

            props.compProp = {
                minValue: data.minValue,
                maxValue: data.maxValue
            };
            props.onInputChange = this.handleViewEvent;
            props.onInputChangedFailed = this.handleViewEvent;
            return componentsCore.compRegistrar.getCompClass(componentType, true)(props);
        }
    };
});
