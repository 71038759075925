define([
    'lodash',
    'santa-core-utils',
    'wixappsCore'
], function (_, coreUtils,
    /** wixappsCore */ wixapps) {
    'use strict';

    const RICH_TYPES = {
        'wix:MediaRichText': true,
        'wix:RichText': true
    };

    function getFontFamiliesFromTextData(partData) {
        if (!partData) {
            return null;
        }

        return _.reduce(partData, (a, item) =>
            _.reduce(item, (b, field) => {
                const typeName = wixapps.typeNameResolver.getDataItemTypeName(field);
                if (RICH_TYPES[typeName] && _.includes(field.text, 'font-family')) {
                    const fonts = coreUtils.fonts.collectFontsFromTextDataArray(field.text);
                    if (fonts.length) {
                        b = [...b, ...fonts];
                    }
                }
                return b;
            }, a), []);
    }

    function getFontFamiliesFromViewsInDescriptor(descriptor) {
        const fontFamilies = [];
        _.forEach(descriptor.views, function (viewDef) {
            wixapps.viewsUtils.traverseViews(viewDef, function (view) {
                const fontFamily = view.comp && view.comp.fontFamily;
                if (fontFamily) {
                    fontFamilies.push(fontFamily);
                }
            });
        });

        return fontFamilies;
    }

    function getFontFamiliesFromCustomizations(customizations) {
        return _(customizations).filter({key: 'comp.fontFamily'}).map('value').value();
    }

    function getCustomFontFamilies(descriptor, compData) {
        let fontFamilies = [];

        if (descriptor) {
            fontFamilies = fontFamilies.concat(getFontFamiliesFromViewsInDescriptor(descriptor));
            fontFamilies = fontFamilies.concat(getFontFamiliesFromCustomizations(descriptor.customizations));
        }

        const customizations = _.compact(compData.appLogicCustomizations);
        if (customizations) {
            fontFamilies = fontFamilies.concat(getFontFamiliesFromCustomizations(customizations));
        }

        return fontFamilies;
    }

    return {
        getFontFamiliesFromTextData,
        getCustomFontFamilies
    };
});
