define(['lodash',
    'wixappsCore',
    'wixappsClassics/core/data/converters/mediaPostConverter',
    'wixappsClassics/util/blogSinglePostPageLogicUtils',
    'coreUtils'
], function (_, /** wixappsCore */wixapps, mediaPostConverter, blogSinglePostPageLogicUtils, coreUtils) {
    'use strict';

    const MAX_READ_TIME_TO_REPORT_MS = 1800000; // 30 minutes

    const wixappsClassicsLogger = coreUtils.wixappsClassicsLogger;
    const blogAppPartNames = coreUtils.blogAppPartNames;
    const socialCounterDatabaseAPI = coreUtils.socialCounterDatabaseAPI;
    const initialCounters = {
        like: 0,
        share_google: 0,
        share_facebook: 0,
        share_twitter: 0,
        share_pinterest: 0,
        share_whatsapp: 0,
        share_linkedin: 0
    };

    function convertLargeNumberToLetterNotation(number) {
        if (number >= 1000000000) {
            return `${(number / 1000000000).toFixed(1).replace(/\.0$/, '')}G`;
        }
        if (number >= 1000000) {
            return `${(number / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
        }
        if (number >= 1000) {
            return `${(number / 1000).toFixed(1).replace(/\.0$/, '')}K`;
        }
        return number;
    }

    function calculateCounters(countersData) {
        const resultObject = {};
        _.forEach(countersData.rows, function (item) {
            if (item.shareDestination !== 'null') {
                resultObject[`share_${item.shareDestination}`] = convertLargeNumberToLetterNotation(item.shares);
            }
            if (item.likes > 0) {
                resultObject.like = convertLargeNumberToLetterNotation(item.likes);
            }
        });

        for (const counter in initialCounters) {
            if (!resultObject[counter]) {
                resultObject[counter] = convertLargeNumberToLetterNotation(initialCounters[counter]);
            }
        }
        return resultObject;
    }

    function getPhotoOfSharedPost(post) {
        let photoUrl;
        if (post && post._type) {
            switch (post._type) {
                case 'PhotoPost':
                    photoUrl = post.photo && this.partApi.resolveImageData(post.photo).src;
                    break;
                case 'VideoPost':
                    photoUrl = post.video && post.video.imageSrc;
                    break;
                default:
                    photoUrl = '';
                    break;
            }
        }
        return photoUrl;
    }

    function getSharedPostData() { // eslint-disable-line complexity
        let post = blogSinglePostPageLogicUtils.getSinglePost(this.partApi);
        post = mediaPostConverter.convertMediaPost(_.cloneDeep(post));

        let postUrl = this.partApi.getCurrentUrl();
        if (post.permalink && post.permalinkVersion && post.permalinkVersion.toString() === '1') {
            postUrl = this.partApi.getCurrentUrlInFormat(coreUtils.siteConstants.URL_FORMATS.HASH_BANG);
        }

        if (post.httpsOnPublish && postUrl.indexOf('http://') === 0) {
            postUrl.replace('http://', 'https://');
        }
        if (!post.httpsOnPublish && postUrl.indexOf('https://') === 0) {
            postUrl.replace('https://', 'http://');
        }

        const imgSrc = getPhotoOfSharedPost.call(this, post) || '';

        const hashTags = post.tags.filter(function onlyHashTags(tag) {
            return tag[0] === '#';
        }).join(' ');

        return {
            storeId: this.partApi.getAppService(),
            postId: post._iid,
            title: post.title,
            postUrl,
            imgSrc,
            hashTags
        };
    }

    function savePostUrlMapping(post, partApi) {
        if (post.permalink) {
            const permalink = post.permalink;
            const title = _.unescape(post.title).replace(/(?![a-z0-9])(?!\s)[\x00-\x7F]/gi, '').replace(/\s+/g, '-'); //eslint-disable-line no-control-regex
            partApi.setCustomUrlMapping(permalink, {id: post._iid, title});
        }
    }

    function shouldClearSocialCounterState(post) {
        return post && _.isEmpty(post);
    }

    function clearSocialCounterState() {
        this.currentPostId = null;
        this.socialCountersPostId = null;
        this.socialCountersInfo = {};
    }

    function updateSocialCounterState(post) {
        this.currentPostId = post._iid;

        if (!this.partApi.isInSSR()) {
            if (this.socialCountersPostId) {
                this.reportReadFinished();
            }
            this.socialCountersPostId = post._iid;
            this.socialCountersInfo = {};

            const postShareData = getSharedPostData.call(this);
            const storeId = this.partApi.getAppService().datastoreId;
            socialCounterDatabaseAPI.updateCounter('viewsStarted', null, 1, postShareData.storeId, this.socialCountersPostId);
            this.viewStartTime = Date.now();
            this.tryCount = 1;

            socialCounterDatabaseAPI.getAllCountersForPost(storeId, post._iid, this.countersRequestSuccessfulCallback.bind(this), this.countersRequestFailedCallback.bind(this));
        }
    }

    const logicFactory = wixapps.logicFactory;

    /**
     * @class core.SinglePostPageLogic
     * @param partApi
     * @constructor
     */
    function SinglePostPageLogic(partApi) {
        this.partApi = partApi;
    }

    SinglePostPageLogic.prototype = {
        sharePost(evt, domID, useOriginalUrl) {
            const partApi = this.partApi;

            const postData = getSharedPostData.call(this);
            const siteId = partApi.getSiteId();

            function handleShareRequest(url) {
                partApi.reportEvent(wixappsClassicsLogger.events.SHARE_CLICKED, {
                    type: evt.params.service,
                    post_id: postData.postId,
                    site_id: siteId
                });

                partApi.handleShareRequest({
                    url,
                    service: evt.params.service,
                    title: postData.title,
                    hashTags: postData.hashTags || '',
                    imageUrl: postData.imgSrc,
                    postId: postData.postId,
                    storeId: postData.storeId,
                    addDeepLinkParam: false
                });
            }

            if (useOriginalUrl || partApi.getUrlFormat() === coreUtils.siteConstants.URL_FORMATS.SLASH) {
                handleShareRequest(postData.postUrl);
            } else {
                partApi.shortenURL(postData.postUrl, 2000, handleShareRequest, handleShareRequest.bind(null, postData.postUrl));
            }
        },

        toggleLikeForPost(evt) {
            const postShareData = getSharedPostData.call(this);
            let newValue;
            const counterToUpdate = 'likes';
            let counterValue;
            if (evt.params.isLiked) {
                this.socialCountersInfo.like--;
                newValue = false;
                counterValue = -1;
            } else {
                this.socialCountersInfo.like++;
                newValue = true;
                counterValue = 1;
            }
            if (!this.partApi.isLayoutRootProxyReady()) {
                _.defer(this.toggleLikeForPost.bind(this, evt));
                return;
            }

            this.partApi.setVarOfLayoutRootProxy('$isPostLiked', newValue);
            this.partApi.setVarOfLayoutRootProxy('$likeStateChanged', true);
            this.partApi.setVarOfLayoutRootProxy('socialCounters', this.socialCountersInfo);
            this.partApi.reportEvent(wixappsClassicsLogger.events.LIKE_CLICKED, {
                like_status: counterToUpdate,
                post_id: postShareData.postId
            });
            socialCounterDatabaseAPI.updateCounter(counterToUpdate, null, counterValue, postShareData.storeId, postShareData.postId);
        },

        sharePostWithOriginalUrl(evt, domID) {
            this.sharePost(evt, domID, true);
        },


        preventClickPropagation(event) {
            if (this.partApi.isSelectionSharerVisible()) {
                event.stopPropagation();
            }
        },

        clearSelection(event) {
            if (event.button === 0) {
                const selection = window.getSelection();
                if (selection.empty) {
                    selection.empty();
                }
                if (selection.removeAllRanges) {
                    selection.removeAllRanges();
                }
            }
        },

        startSelection(event) {
            if (!event.params.enabled) {
                return;
            }
            const sel = window.getSelection();
            let position = {};
            const partApi = this.partApi;
            if (sel.rangeCount && sel.toString().length > 0) {
                const postData = getSharedPostData.call(this);

                const selRect = sel.getRangeAt(0).getBoundingClientRect();
                const popUpWidth = 142;
                const popUpHeight = 45 + 10;
                position = {
                    x: (selRect.left + selRect.right) / 2 - popUpWidth / 2 + (window.document.body.scrollLeft || window.document.documentElement.scrollLeft), // eslint-disable-line no-mixed-operators
                    y: selRect.top - popUpHeight + (window.document.body.scrollTop + window.document.documentElement.scrollTop)
                };
                const container = window.document.createElement('div');
                for (let i = 0, len = sel.rangeCount; i < len; ++i) {
                    container.appendChild(sel.getRangeAt(i).cloneContents());
                }

                const shareInfo = {
                    siteId: this.partApi.getSiteId(),
                    url: postData.postUrl,
                    service: '',
                    title: postData.title,
                    postId: postData.postId,
                    storeId: this.partApi.getAppService(),
                    description: container.textContent,
                    hashTags: postData.hashTags || '',
                    imageUrl: postData.imgSrc,
                    addDeepLinkParam: false
                };
                partApi.showSelectionSharer(position, shareInfo);
            } else {
                partApi.hideSelectionSharer();
            }
        },

        facebookShareRequestSuccessfulCallback(result) {
            this.socialCountersInfo.share_facebook = convertLargeNumberToLetterNotation(result.share.share_count);
            this.partApi.setVarOfLayoutRootProxy('socialCounters', this.socialCountersInfo);
        },

        facebookShareRequestFailureCallback() {
            this.partApi.setVarOfLayoutRootProxy('socialCounters', this.socialCountersInfo);
        },

        requestFacebookCountOfShares() {
            const postData = getSharedPostData.call(this);
            const url = `https://graph.facebook.com/${postData.postUrl}`;
            coreUtils.ajaxLibrary.ajax({
                type: 'GET',
                url,
                contentType: 'application/json',
                success: this.facebookShareRequestSuccessfulCallback.bind(this) || this.defaultQuerySuccessCallback,
                error: this.facebookShareRequestFailureCallback.bind(this)
            });
        },

        countersRequestSuccessfulCallback(result) {
            if (result.firstTime) {
                this.socialCountersInfo = initialCounters;
            } else {
                this.socialCountersInfo = calculateCounters(result);
            }
            if (this.partApi.isExperimentOpen('sv_blogRequestShareCountFromFacebook')) {
                this.requestFacebookCountOfShares();
            } else {
                this.partApi.setVarOfLayoutRootProxy('socialCounters', this.socialCountersInfo);
            }
        },

        countersRequestFailedCallback() {
            this.tryCount++;
            if (this.tryCount < 2) {
                const post = blogSinglePostPageLogicUtils.getSinglePost(this.partApi);
                setTimeout(socialCounterDatabaseAPI.getAllCountersForPost.bind(post._iid, this.countersRequestSuccessfulCallback.bind(this), this.countersRequestFailedCallback.bind(this)), 1000);
            }
        },

        reportReadFinished() {
            const postShareData = getSharedPostData.call(this);
            socialCounterDatabaseAPI.updateCounter('viewsEnded', null, 1, postShareData.storeId, this.socialCountersPostId);
            const timeSpentInPost = Date.now() - this.viewStartTime;
            if (timeSpentInPost <= MAX_READ_TIME_TO_REPORT_MS) {
                const timeInSeconds = Math.floor(timeSpentInPost / 1000);
                socialCounterDatabaseAPI.updateCounter('viewsTotalTime', null, timeInSeconds, postShareData.storeId, this.socialCountersPostId);
            }
        },

        isReady() {
            const post = blogSinglePostPageLogicUtils.getSinglePost(this.partApi);
            if (post) {
                return true;
            }
            return false;
        },

        isReadySideEffects() {
            const post = blogSinglePostPageLogicUtils.getSinglePost(this.partApi);

            if (shouldClearSocialCounterState(post)) {
                clearSocialCounterState.call(this);
                return;
            }
            if (!_.isEmpty(post) && (!this.currentPostId || this.currentPostId !== post._iid)) {
                updateSocialCounterState.call(this, post);

                const transformedPost = blogSinglePostPageLogicUtils.transformPostIfPreview(post, this.partApi);
                blogSinglePostPageLogicUtils.updatePageTitleAndMetaTags(transformedPost, this.partApi);
                savePostUrlMapping(transformedPost, this.partApi);
            }
        },

        beforeClose() {
            if (this.socialCountersPostId) {
                this.reportReadFinished();
            }
        }
    };

    logicFactory.register(blogAppPartNames.SINGLE_POST, SinglePostPageLogic);
});
