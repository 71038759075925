define([
    'lodash',
    'santa-components',
    'wixappsCore',
    'coreUtils',
    'wixappsClassics/comps/appPart',
    'wixappsClassics/ecommerce/util/ecomLogger',
    'wixappsClassics/ecommerce/util/ecomDataUtils',
    'wixappsClassics/ecommerce/util/thankYouPageHandler',
    'wixappsClassics/ecommerce/data/cartManager',
    'wixappsClassics/ecommerce/util/checkoutUrlUtil'
], function (_, santaComponents, wixapps, coreUtils, appPart, ecomLogger, ecomDataUtils, thankYouPageHandler, cartManager, checkoutUrlUtil) {
    'use strict';

    const createWixappsComponentFetcher = wixapps.wixappsSantaTypesUtil.createWixappsComponentFetcher;
    const {createComponentSantaTypeFetcher, createGlobalSantaTypesFetcher} = coreUtils.propsSelectorsUtils;
    const {santaTypesDefinitions} = santaComponents;
    const cart = createWixappsComponentFetcher(function (state) {
        return ecomDataUtils.getApplicationDataStore(state.siteData).items.cart;
    });

    const showMessage = createGlobalSantaTypesFetcher(state => {
        const ecomDialogAspect = state.siteAPI.getSiteAspect('ecomDialog');
        return ecomDialogAspect.showMessage;
    }, 'ecom.showMessage');

    const showModal = createGlobalSantaTypesFetcher(state => {
        const ecomDialogAspect = state.siteAPI.getSiteAspect('ecomCheckout');
        return ecomDialogAspect.showModal;
    }, 'ecom.showModal');

    const showCheckoutDialogForOwner = createGlobalSantaTypesFetcher(state => {
        const ecomDialogAspect = state.siteAPI.getSiteAspect('ecomDialog');
        return ecomDialogAspect.showCheckoutDialogForOwner;
    }, 'ecom.showCheckoutDialogForOwner');

    const ecomTpaProductPageId = createGlobalSantaTypesFetcher(state => {
        const ECOM_TPA_PRODUCT_PAGE_ID = 'product_page';
        const sitePagesMap = state.siteData.getPagesDataItems();
        const productPageData = _.find(sitePagesMap, {tpaPageId: ECOM_TPA_PRODUCT_PAGE_ID});
        return _.get(productPageData, 'id');
    }, 'ecomTpaProductPageId');

    const isEcomTpaExistsAndEcomListNot = createComponentSantaTypeFetcher((state, props) => {
        const ECOM_TPA_APP_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd';

        const isListAppInstalled = !!state.fetchSantaType(wixapps.CoreSantaTypes.appService, state, props);

        let isEcomTpaInstalled = false;
        if (!isListAppInstalled) {
            const appData = state.siteData.getClientSpecMapEntryByAppDefinitionId(ECOM_TPA_APP_DEF_ID);
            isEcomTpaInstalled = !_.get(appData, 'permissions.revoked');
        }

        return isEcomTpaInstalled && !isListAppInstalled;
    });

    const productIdFromUrl = createComponentSantaTypeFetcher((state, props) => {
        const rootNavigationInfo = state.fetchSantaType(santaTypesDefinitions.Component.rootNavigationInfo, state, props);
        const pageAdditionalData = _.get(rootNavigationInfo, 'pageAdditionalData') || '';
        return pageAdditionalData.split('/')[0];
    });

    const reportEvent = createGlobalSantaTypesFetcher(function (state) {
        return ecomLogger.reportEvent.bind(ecomLogger, state.siteData);
    }, 'ecom.reportEvent');

    const reportError = createGlobalSantaTypesFetcher(function (state) {
        return ecomLogger.reportError.bind(ecomLogger, state.siteData);
    }, 'ecom.reportError');

    const hasPremium = createGlobalSantaTypesFetcher(function (state) {
        return _.includes(state.siteData.getPremiumFeatures(), 'HasECommerce');
    }, 'ecom.hasPremium');

    const thankYouPageUrl = createComponentSantaTypeFetcher((state, props) => {
        const partDefinition = state.fetchSantaType(appPart.statics.santaTypeDefinitions.wixappsClassics.partDefinition, state, props);
        return partDefinition && thankYouPageHandler.getThankYouPageUrl(state.siteData, partDefinition);
    });

    const cartId = createComponentSantaTypeFetcher((state, props) => {
        const siteId = santaTypesDefinitions.RendererModel.siteId.fetch(state, props);
        return state.siteData.requestModel.storage.local.getItem(`eCommerce_${siteId}`);
    });

    const removeProduct = createGlobalSantaTypesFetcher(state => {
        const wixappsDataAspect = state.siteAPI.getSiteAspect('wixappsDataAspect');
        return function (product, onFailCallback) {
            return cartManager.removeProduct(product, state.siteData, wixappsDataAspect, onFailCallback);
        };
    }, 'ecom.removeProduct');

    const addProduct = createGlobalSantaTypesFetcher(state => {
        const wixappsDataAspect = state.siteAPI.getSiteAspect('wixappsDataAspect');
        return function (productId, options, callback, onFailCallback) {
            return cartManager.addProduct(productId, state.siteAPI, wixappsDataAspect, options, callback, onFailCallback);
        };
    }, 'ecom.addProduct');

    const updateProduct = createGlobalSantaTypesFetcher(state => {
        const wixappsDataAspect = state.siteAPI.getSiteAspect('wixappsDataAspect');
        return function (product, onFailCallback) {
            return cartManager.updateProduct(product, state.siteData, wixappsDataAspect, onFailCallback);
        };
    }, 'ecom.updateProduct');

    const setShipping = createGlobalSantaTypesFetcher(state => {
        const wixappsDataAspect = state.siteAPI.getSiteAspect('wixappsDataAspect');
        return function (countryId, regionId, dontMergeWithOldData, onSuccessCallback, onFailCallback) {
            return cartManager.setShipping(countryId, regionId, state.siteData, wixappsDataAspect, dontMergeWithOldData, onSuccessCallback, onFailCallback);
        };
    }, 'ecom.setShipping');

    const getShipping = createGlobalSantaTypesFetcher(state => {
        const wixappsDataAspect = state.siteAPI.getSiteAspect('wixappsDataAspect');
        return function (onFailCallback) {
            return cartManager.getShipping(state.siteData, wixappsDataAspect, onFailCallback);
        };
    }, 'ecom.getShipping');

    const clearCoupon = createGlobalSantaTypesFetcher(state => {
        const wixappsDataAspect = state.siteAPI.getSiteAspect('wixappsDataAspect');
        return cartManager.clearCoupon.bind(cartManager, state.siteData, wixappsDataAspect);
    }, 'ecom.clearCoupon');

    const setCoupon = createGlobalSantaTypesFetcher(state => {
        const wixappsDataAspect = state.siteAPI.getSiteAspect('wixappsDataAspect');
        return function (couponCode, onError) {
            return cartManager.setCoupon(couponCode, state.siteData, wixappsDataAspect, onError);
        };
    }, 'ecom.setCoupon');

    const setCartItemDataAndUpdate = createGlobalSantaTypesFetcher(state => {
        const wixappsDataAspect = state.siteAPI.getSiteAspect('wixappsDataAspect');
        return function (path, value) {
            return cartManager.setCartItemDataAndUpdate(path, value, wixappsDataAspect);
        };
    }, 'ecom.setCartItemDataAndUpdate');

    const cartPageData = createComponentSantaTypeFetcher((state, props) => {
        const compData = state.fetchSantaType(santaTypesDefinitions.Component.compData, state, props);
        const cartPageId = _.get(compData.appLogicParams, 'cartPageID.value');
        return cartPageId && state.siteData.getDataByQuery(cartPageId);
    });

    const checkoutUrl = createComponentSantaTypeFetcher((state, props) => {
        const packageName = state.fetchSantaType(wixapps.CoreSantaTypes.packageName, state, props);
        const currentCart = state.fetchSantaType(appPart.statics.santaTypeDefinitions.ecom.cart, state, props);
        const partDefinition = state.fetchSantaType(appPart.statics.santaTypeDefinitions.wixappsClassics.partDefinition, state, props);
        if (packageName !== 'ecommerce' || !currentCart || !partDefinition) {
            return '';
        }

        if (currentCart.hasExternalCheckoutUrl === true) {
            return checkoutUrlUtil.getInternalHandledCheckoutUrl(state.siteData, partDefinition);
        }
        return checkoutUrlUtil.getExternalHandledCheckoutUrl(state.siteData, partDefinition);
    });

    return {
        CartManager: {
            setShipping,
            getShipping,
            clearCoupon,
            setCoupon,
            setCartItemDataAndUpdate,
            updateProduct,
            removeProduct,
            addProduct
        },
        cart,
        cartPageData,
        cartId,
        thankYouPageUrl,
        checkoutUrl,
        hasPremium,
        reportEvent,
        reportError,
        ecomTpaProductPageId,
        showMessage,
        showModal,
        showCheckoutDialogForOwner,
        isEcomTpaExistsAndEcomListNot,
        productIdFromUrl
    };
});
