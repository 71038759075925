define([
    'componentsCore',
    'skins',
    'wixappsCore',
    'wixappsClassics/blog/blog',
    'wixappsClassics/comps/appPartZoom',
    'wixappsClassics/util/viewCacheUtils',
    'wixappsClassics/util/numberOfPostsPerPageGetter',
    'wixappsClassics/util/componentTypeUtil',
    'wixappsClassics/util/descriptorUtils',
    'wixappsClassics/util/blogSinglePostPageLogicUtils',
    'wixappsClassics/core/transformMediaItemsResponse',
    'wixappsClassics/skins/skins.json',
    'wixappsClassics/ecommerce/ecommerceSantaTypes',
    'wixappsClassics/core/wixappsClassicsSantaTypes',
    'wixappsClassics/util/appPartCommonDataManager',
    'wixappsClassics/ecommerce/ecommerce',

    'wixappsClassics/core/appPartDataRequirementsChecker',
    'wixappsClassics/core/appPartStyleCollector',

    'wixappsClassics/core/data/converters/mediaPostConverter',
    'wixappsClassics/core/langs/defaultPostsTranslation',

    'wixappsClassics/util/fontUtils',

    //core logics
    'wixappsClassics/core/logics/twoLevelCategoryLogic',
    'wixappsClassics/core/logics/listFromPageLogic',
    'wixappsClassics/core/logics/archiveLogic',
    'wixappsClassics/core/logics/singlePostPageLogic',
    'wixappsClassics/core/logics/customFeedLogic',
    'wixappsClassics/core/logics/relatedPostsLogic',
    'messageView',
    //components
    'table'
], function (componentsCore, skinsPackage, wixappsCore, blog, appPartZoom, viewCacheUtils,
             numberOfPostsPerPageGetter, componentTypeUtil, descriptorUtils, blogSinglePostPageLogicUtils, transformMediaItemsResponse, skinsJson, ecommerceSantaTypes, wixappsClassicsSantaTypes, appPartCommonDataManager) {
    'use strict';

    const functionLibrary = wixappsCore.FunctionLibrary;
    functionLibrary.prototype.addFunctions(blog.functionLibrary);

    componentsCore.compRegistrar.register('wixapps.integration.components.AppPartZoom', appPartZoom);
    skinsPackage.skinsMap.addBatch(skinsJson);
    componentsCore.santaTypesFetchersRegistry.registerFetchersNamespace('ecommerceSantaTypes', ecommerceSantaTypes);
    componentsCore.santaTypesFetchersRegistry.registerFetchersNamespace('wixapssClassicsSantaTypes', wixappsClassicsSantaTypes);
    return {
        appPartCommonDataManager,
        viewCacheUtils,
        numberOfPostsPerPageGetter,
        componentTypeUtil,
        descriptorUtils,
        transformMediaItemsResponse,
        blogSinglePostPageLogicUtils
    };
});
