define([
    'lodash',
    'coreUtils',
    'wixappsCore',
    'wixappsClassics/core/transformAndSetMetaData'
], function (_,
             coreUtils,
             wixappsCore,
             transformAndSetMetaData) {
    'use strict';

    const urlUtils = coreUtils.urlUtils;
    const wixappsDataHandler = wixappsCore.wixappsDataHandler;

    function getRequest(siteData, compData, appService) {
        if (appService.packageName !== 'blog') {
            return [];
        }
        const PATH = ['wixapps', 'blog', 'hasSettingsRequested'];

        if (_.get(siteData, PATH)) {
            return [];
        }
        _.set(siteData, PATH, true);

        const url = `${urlUtils.baseUrl(siteData.getExternalBaseUrl())}/apps/lists/1/Query?consistentRead=false`;

        const data = {
            collectionId: 'Settings',
            filter: {_iid: 'blogSettings'},
            storeId: appService.datastoreId
        };

        const transformFunc = function (responseData, currentValue) {
            currentValue.settings = _.get(responseData.payload, 'items[0].settingsObject', {}); // eslint-disable-line santa/no-side-effects
            return currentValue;
        };

        return [{
            force: true,
            destination: wixappsDataHandler.getSiteDataDestination(appService.packageName),
            url,
            data,
            transformFunc: transformAndSetMetaData.bind(this, transformFunc, siteData, appService.packageName, compData.id)
        }];
    }

    function getBlogSettings(siteData) {
        const blogData = wixappsDataHandler.getPackageData(siteData, 'blog');
        const defaultSettings = {
            locale: 'en',
            email: false
        };

        return _.get(blogData, 'settings', defaultSettings);
    }

    return {
        getRequest,
        get: getBlogSettings
    };
});
