define([
    'lodash',
    'wixappsCore',
    'wixappsClassics/ecommerce/data/ecomDataRequirementsChecker',
    'wixappsClassics/core/batchableListsRequestsMerger',
    'wixappsClassics/core/data/converters/mediaPostConverter',
    'wixappsClassics/core/transformAndSetMetaData',
    'wixappsClassics/core/blogCategories',
    'wixappsClassics/util/appPartCommonDataManager',
    'wixappsClassics/util/numberOfPostsPerPageGetter',
    'wixappsClassics/util/descriptorUtils',
    'wixappsClassics/core/relatedPosts',
    'wixappsClassics/core/transformMediaItemsResponse',
    'experiment',
    'coreUtils',
    'componentsCore'
], function (
    _,
    wixapps,
    ecomDataRequirementsChecker,
    batchableListsRequestsMerger,
    mediaPostConverter,
    transformAndSetMetaData,
    blogCategories,
    appPartCommonDataManager,
    numberOfPostsPerPageGetter,
    descriptorUtils,
    relatedPosts,
    transformMediaItemsResponse,
    experiment,
    coreUtils,
    componentsCore
) {
    'use strict';
    /*eslint max-statements: ["error", 80]*/

    const objectUtils = coreUtils.objectUtils;
    const urlUtils = coreUtils.urlUtils;
    const wixappsDataHandler = wixapps.wixappsDataHandler;
    const videoThumbDataHandler = wixapps.videoThumbDataHandler;
    const wixappsLogger = wixapps.wixappsLogger;
    const wixappsClassicsLogger = coreUtils.wixappsClassicsLogger;
    const blogAppPartNames = coreUtils.blogAppPartNames;
    const appSpecificFilterMap = {
        'Posts': {
            'query': {
                'scheduled.iso': {
                    '$not': {
                        '$gt': '$now'
                    }
                },
                'deleted': {'$ne': true}
            },
            'groupByAndCount': {
                'scheduled.iso': {
                    '$not': {
                        '$gt': '$now'
                    }
                },
                'draft': false,
                'deleted': {'$ne': true}
            }
        }
    };
    const partsQueryMap = {};
    const privates = new coreUtils.SiteDataPrivates(); //eslint-disable-line santa/no-module-state

    /*********************** Blog *****************************/
    partsQueryMap[blogAppPartNames.ARCHIVE] = {
        method: archiveGroupByAndCount,
        defaultOptions: {
            'collectionId': 'Posts',
            'type': 'Post',
            'field': 'date.iso',
            'project': {
                'date': {
                    'iso': {
                        '$substr': [
                            '$date.iso',
                            0,
                            7
                        ]
                    }
                }
            },
            'filter': {
                'draft': false
            },
            'sort': 'byKeyDesc',
            'normalizeTo': 5,
            'limit': null
        }
    };

    partsQueryMap[blogAppPartNames.CUSTOM_FEED] = {
        method: queryAndHandleMediaPostWithUrllessPagination,
        defaultOptions: {
            'collectionId': 'Posts',
            'limit': '',
            'sort': {'date.iso': -1},
            'filterView': 'filter',
            'filterType': 'PostFilter',
            'resultType': 'Post',
            'fields': ['title', 'text', 'coverImageData', 'date', 'permalink', 'permalinkVersion', 'author', 'categoryIds', 'video', 'photo', 'defaultPost', 'description', 'mobileTitle']
        }
    };

    partsQueryMap[blogAppPartNames.POSTS_GALLERY] = {
        method: queryAndHandleMediaPost,
        defaultOptions: {
            'collectionId': 'Posts',
            'limit': 30,
            'sort': {'date.iso': -1},
            'filterView': 'filter',
            'filterType': 'PostFilter',
            'resultType': 'Post',
            'fields': ['title', 'text', 'coverImageData', 'date', 'permalink', 'permalinkVersion', 'video', 'photo', 'defaultPost', 'description', 'mobileTitle']
        }
    };

    partsQueryMap[blogAppPartNames.TICKER] = {
        method: queryAndHandleMediaPost,
        defaultOptions: {
            'collectionId': 'Posts',
            'limit': 10,
            'sort': {'date.iso': -1},
            'filterView': 'filter',
            'filterType': 'PostFilter',
            'resultType': 'Post',
            'fields': ['title', 'text', 'coverImageData', 'date', 'permalink', 'permalinkVersion', 'video', 'photo', 'defaultPost', 'description', 'mobileTitle']
        }
    };

    partsQueryMap[blogAppPartNames.FEATURED_POSTS] = {
        method: queryAndHandleMediaPost,
        defaultOptions: {
            'collectionId': 'Posts',
            'limit': 10,
            'sort': {'date.iso': -1},
            'filter': {
                'featured': true
            },
            'filterView': 'filter',
            'filterType': 'PostFilter',
            'resultType': 'Post',
            'fields': ['title', 'text', 'coverImageData', 'date', 'permalink', 'permalinkVersion', 'video', 'photo', 'defaultPost', 'description', 'mobileTitle']
        }
    };

    partsQueryMap[blogAppPartNames.POSTS_LIST] = {
        method: queryAndHandleMediaPost,
        defaultOptions: {
            'collectionId': 'Posts',
            'limit': 10,
            'sort': {'date.iso': -1},
            'filterView': 'filter',
            'filterType': 'PostFilter',
            'resultType': 'Post',
            'fields': ['title', 'text', 'coverImageData', 'date', 'permalink', 'permalinkVersion', 'video', 'photo', 'defaultPost', 'description', 'mobileTitle']
        }
    };

    partsQueryMap[blogAppPartNames.AUTHOR] = {
        method: query,
        defaultOptions: {
            'collectionId': 'Posts',
            'limit': 10,
            'sort': {'date.iso': -1},
            'filterView': 'filter',
            'filterType': 'PostFilter',
            'resultType': 'Post'
        }
    };

    partsQueryMap[blogAppPartNames.FEED] = {
        method: queryFromUrlAndHandleMediaPost,
        defaultOptions: {
            'getTotalCount': true,
            'collectionId': 'Posts',
            'limit': 10,
            'sort': '{"date.iso":-1}',
            'fields': ['title', 'text', 'coverImageData', 'date', 'permalink', 'permalinkVersion', 'author', 'categoryIds', 'video', 'photo', 'defaultPost', 'description', 'mobileTitle']
        }
    };

    partsQueryMap[blogAppPartNames.TAG_CLOUD] = {
        method: groupByAndCount,
        defaultOptions: {
            'collectionId': 'Posts',
            'type': 'Post',
            'field': 'tags',
            'filter': {},
            'sort': 'byKeyAsc',
            'normalizeTo': 5
        }
    };

    partsQueryMap[blogAppPartNames.SINGLE_POST] = {
        method: readItemFromUrl,
        defaultOptions: {
            'collectionId': 'Posts',
            'acceptableTypes': [
                'PhotoPost',
                'VideoPost',
                'TextPost'
            ],
            'filter': {
                'draft': false
            },
            'sort': {'date.iso': -1}
        }
    };

    partsQueryMap[blogAppPartNames.CATEGORIES] = {
        method: blogCategories.queryBlogCategoryPostCounts
    };

    partsQueryMap[blogAppPartNames.RELATED_POSTS] = {
        method: relatedPosts.readRelatedPosts,
        defaultOptions: {
            'collectionId': 'RelatedPosts'
        }
    };

    partsQueryMap[blogAppPartNames.HERO_IMAGE] = {
        method: readItemFromUrl,
        defaultOptions: {
            'collectionId': 'Posts',
            'filter': {
                'draft': false
            },
            'sort': {'date.iso': -1}
        },
        isFeatureOpen(siteData) {
            return experiment.isOpen('sv_blogHeroImage', siteData);
        }
    };

    /***************************** Menu *****************************/
    partsQueryMap['1660c5f3-b183-4e6c-a873-5d6bbd918224'] = {
        method: readItem,
        defaultOptions: {
            'collectionId': 'Menus',
            'itemId': 'SampleMenu1'
        }
    };

    /***************************** FAQ *****************************/
    partsQueryMap['f2c4fc13-e24d-4e99-aadf-4cff71092b88'] = {
        method: readItem,
        defaultOptions: {
            'collectionId': 'FAQs',
            'itemId': 'SampleMenu1'
        }
    };

    /***************************** NEWS *****************************/
    partsQueryMap['045dd836-ef5d-11e1-ace3-c0dd6188709b'] = {
        method: readItem,
        defaultOptions: {
            'collectionId': 'Lists',
            'itemId': 'SampleFeed1'
        }
    };

    partsQueryMap['63631b64-a981-40c3-8772-40238db5aff6'] = {
        method: readItem,
        defaultOptions: {
            'collectionId': 'Items',
            'itemId': '0537E434-5F86-4392-BEF5-7DC62B8412B3'
        }
    };

    Object.freeze(partsQueryMap);

    function getMap(siteData, mapName) {
        if (!privates.has(siteData)) {
            privates.set(siteData, {});
        }

        const sitePrivates = privates.get(siteData);
        sitePrivates[mapName] = sitePrivates[mapName] || {};

        return sitePrivates[mapName];
    }

    function isTimeoutDisabled(siteData) {
        return siteData.isInSSR();
    }

    function compare(a, b) {
        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        return 0;
    }

    const sortOptions = {
        'byKeyAsc'(a, b) {
            return compare(a.key, b.key);
        },
        'byKeyDesc'(a, b) {
            return compare(b.key, a.key);
        },
        'byCountAsc'(a, b) {
            return compare(a.count, b.count);
        },
        'byCountDesc'(a, b) {
            return compare(b.count, a.count);
        }
    };

    function getItemId(item) {
        return item._iid;
    }

    const MONTHS_TRANSLATION = {
        '01': 'January',
        '02': 'February',
        '03': 'March',
        '04': 'April',
        '05': 'May',
        '06': 'June',
        '07': 'July',
        '08': 'August',
        '09': 'September',
        '10': 'October',
        '11': 'November',
        '12': 'December'
    };

    const getDateWithCounter = function (date, count) {
        const dateParts = date.split('-');
        return `${MONTHS_TRANSLATION[dateParts[1]]} ${dateParts[0]} (${count})`;
    };

    function getLang(siteData) {
        const cookie = coreUtils.siteDataUtils.getRequestModel(siteData, 'cookie');
        const languageFromModel = coreUtils.siteDataUtils.getRequestModel(siteData, 'language');
        return coreUtils.wixUserApi.getLanguage(cookie, siteData.currentUrl, languageFromModel).toLowerCase() || 'en';
    }

    const blogAppPartHashToName = Object.freeze(_.invert(coreUtils.blogAppPartNames));

    function reportPerformanceBiEvent(siteData, compData, startTime) {
        const biCountByCompId = getMap(siteData, 'biCountByCompId');

        if (siteData.isViewerMode()) {
            wixappsClassicsLogger.reportEvent(
                siteData,
                wixappsClassicsLogger.events.APP_PART_DATA_REQUEST_FINISHED,
                {
                    component_id: compData.id,
                    app_part_name: blogAppPartHashToName[compData.appPartName],
                    duration: _.now() - startTime,
                    is_batched: true,
                    occurrence_count: biCountByCompId[compData.id] || 0,
                    site_id: siteData.siteId
                }
            );
        }
        biCountByCompId[compData.id] = biCountByCompId[compData.id] + 1 || 1;
    }

    function transformArchiveItemsResponse(compId, collectionId, keyGen, options, responseData, currentValue) {
        const _itemToDate = function (key) {
            const date = key.split('-');
            const d = {_type: 'wix:Date', iso: null};
            const iso = new Date(0);
            iso.setFullYear(date[0]);
            iso.setDate(15);
            const monthInt = parseInt(date[1], 10);
            iso.setMonth(isNaN(monthInt) ? date[1] - 1 : monthInt - 1);
            d.iso = iso.toString();

            return d;
        };
        const comboOptionsItems = _(responseData.payload)
            .map(function (value, key) {
                return {
                    _type: 'Option',
                    text: getDateWithCounter(key, value),
                    value: key,
                    dateValue: _itemToDate(key),
                    count: value,
                    selected: false
                };
            })
            .sortBy(function (option) {
                return Date.parse(option.dateValue.iso);
            })
            .reverse().value();

        const selectAllValue = null;

        function getSelectAllText(selectedValue) {
            return selectedValue === selectAllValue ? 'Select Month' : 'Show All';
        }

        comboOptionsItems.unshift({_type: 'Option', text: getSelectAllText(null), getText: getSelectAllText, value: 0, dateValue: null, count: -1, selected: true});

        const archiveData = {
            _type: 'ComboOptionsList',
            title: '',
            items: comboOptionsItems,
            selectedValue: selectAllValue
        };

        const itemKey = keyGen();
        currentValue[compId] = [collectionId, itemKey];

        currentValue.items = currentValue.items || {};
        currentValue.items[collectionId] = currentValue.items[collectionId] || {};
        currentValue.items[collectionId][itemKey] = archiveData;

        return currentValue;
    }

    function transformItemsResponse(compId, collectionId, responseData, currentValue) {
        currentValue[`${compId}_extraData`] = _.omit(responseData.payload, ['items', 'referencedItems', 'unreferencedItems']);
        currentValue[compId] = _.map(responseData.payload.items, function (item) {
            return [collectionId, getItemId(item)];
        });

        currentValue.items = currentValue.items || {};
        currentValue.items[collectionId] = currentValue.items[collectionId] || {};

        _.forEach(responseData.payload.items, function (item) {
            currentValue.items[collectionId][getItemId(item)] = item;
        });

        _.forEach(responseData.payload.referencedItems, function (refItem, refItemKey) {
            const colId = refItemKey.split('/')[0];
            const iid = refItemKey.split('/')[1];
            currentValue.items[colId] = currentValue.items[colId] || {};
            currentValue.items[colId][iid] = refItem;
        });

        return currentValue;
    }

    function transformItemResponse(compId, collectionId, responseData, currentValue) {
        const itemId = getItemId(responseData.payload.item);
        currentValue[`${compId}_extraData`] = _.omit(responseData.payload, ['item', 'referencedItems', 'unreferencedItems']);
        currentValue[compId] = [collectionId, itemId];

        currentValue.items = currentValue.items || {};
        currentValue.items[collectionId] = currentValue.items[collectionId] || {};

        // will fix the data if it's media post
        currentValue.items[collectionId][itemId] = mediaPostConverter.fixMediaPostDataRefs(responseData.payload.item);

        _.forEach(responseData.payload.referencedItems, function (refItem, refItemKey) {
            const colId = refItemKey.split('/')[0];
            const iid = refItemKey.split('/')[1];
            currentValue.items[colId] = currentValue.items[colId] || {};
            currentValue.items[colId][iid] = refItem;
        });

        return currentValue;
    }

    function transformItemResponseFromUrl(compId, collectionId, responseData, currentValue, siteData) {
        currentValue = transformItemResponse(compId, collectionId, responseData, currentValue);
        const itemId = getItemId(responseData.payload.item);

        let fixedItem = mediaPostConverter.addAuthorFieldWhenMissing(currentValue.items[collectionId][itemId]);
        fixedItem = mediaPostConverter.getPostWithConvertedMobileTitle(fixedItem);
        if (fixedItem.deleted) {
            currentValue.items[collectionId][itemId] = [];
        } else {
            fixedItem = mediaPostConverter.translateDefaultPosts(fixedItem, getLang(siteData));

            mediaPostConverter.fixMasterPageIdInLinksInside(fixedItem);

            currentValue.items[collectionId][itemId] = fixedItem;
            mediaPostConverter.resolveCategories(currentValue, fixedItem);
        }

        if (siteData && siteData.isUsingUrlFormat(coreUtils.siteConstants.URL_FORMATS.SLASH) && coreUtils.stringUtils.startsWith(siteData.currentUrl.hash, '#!')) {
            siteData.currentUrl.full = siteData.currentUrl.full.replace(`/${itemId}`, `/${fixedItem.permalink}`);
        }

        return currentValue;
    }

    function transformSingleItemFromQuery(compId, collectionId, responseData, currentValue, siteData) {
        if (responseData.payload.items && responseData.payload.items.length > 0) {
            responseData.payload.item = responseData.payload.items[0];
            return transformItemResponseFromUrl(compId, collectionId, responseData, currentValue, siteData);
        }

        currentValue.items = currentValue.items || {};
        currentValue.items[collectionId] = currentValue.items[collectionId] || {};
        currentValue.items[collectionId]['-1'] = [];
        currentValue[compId] = [collectionId, ['-1']];

        return currentValue;
    }

    function generateGroupByKey(postData) {
        return JSON.stringify(postData).replace(/\./g, '');
    }

    function transformGroupByResponse(compId, collectionId, keyGen, options, responseData, currentValue) { // eslint-disable-line complexity
        const itemKey = keyGen();
        currentValue[compId] = [collectionId, itemKey];

        currentValue.items = currentValue.items || {};
        currentValue.items[collectionId] = currentValue.items[collectionId] || {};
        currentValue.items[collectionId][itemKey] = _.map(responseData.payload, function (count, key) {
            return {
                key,
                count
            };
        });

        if (options.sort && sortOptions[options.sort]) {
            currentValue.items[collectionId][itemKey] = currentValue.items[collectionId][itemKey].sort(sortOptions[options.sort]);
        }
        if (options.limit) {
            currentValue.items[collectionId][itemKey] = currentValue.items[collectionId][itemKey].slice(0, options.limit);
        }
        if (options.normalizeTo) {
            const normalizeMax = parseInt(options.normalizeTo, 10);
            const maxItem = _.maxBy(currentValue.items[collectionId][itemKey], 'count');
            const max = maxItem ? maxItem.count : -1;
            _.forEach(currentValue.items[collectionId][itemKey], function (o) {
                o.normalized = Math.ceil(o.count * normalizeMax / max); // eslint-disable-line santa/no-side-effects
            });
        }

        return currentValue;
    }

    function getRequestCustomParams(logicParams) {
        const ret = {};
        _.forOwn(logicParams, function (param, key) {
            if (param && param.value) {
                if (_.isString(param.value) && param.value.charAt(0) === '{') {
                    ret[key] = JSON.parse(param.value);
                } else {
                    ret[key] = param.value;
                }
            }
        });
        return ret;
    }

    function serializeRequests(requests) {
        return JSON.stringify(_.map(requests, function (request) {
            return [request.data, request.url];
        }));
    }

    function hasPartsQuery(appPartName, siteData) {
        const appPartQuery = partsQueryMap[appPartName];
        return appPartQuery && (!appPartQuery.isFeatureOpen || appPartQuery.isFeatureOpen(siteData));
    }

    function createPartDataRequest(siteData, compData, appService, urlData) { // eslint-disable-line complexity
        const packageName = appService.packageName;
        const params = getRequestCustomParams(compData.appLogicParams);

        if (!hasPartsQuery(compData.appPartName, siteData)) {
            return [];
        }

        params.collectionId = params.collectionId || _.get(partsQueryMap[compData.appPartName], ['defaultOptions', 'collectionId']);

        let dataRequests = partsQueryMap[compData.appPartName].method(siteData, compData, appService, params, urlData);
        dataRequests = dataRequests && (_.isArray(dataRequests) ? dataRequests : [dataRequests]);

        const currentRequestsKey = serializeRequests(dataRequests);

        // make sure we have a request cache
        objectUtils.ensurePath(siteData, ['wixapps', packageName, 'requestCache']);
        const requestCache = objectUtils.resolvePath(siteData, ['wixapps', packageName, 'requestCache']);

        const lastRequestKey = requestCache[compData.id];
        if (lastRequestKey === currentRequestsKey) {
            _(dataRequests)
                .filter('callback')
                .forEach(function (req) {
                    req.callback(objectUtils.resolvePath(siteData, req.destination));
                });
            return [];
        }
        requestCache[compData.id] = currentRequestsKey;

        const descriptor = wixappsDataHandler.getDescriptor(siteData, packageName);
        const stillLoading = dataRequests.length || !descriptor;
        if (stillLoading) {
            const metadata = {
                dataReady: !dataRequests.length,
                loading: true
            };
            wixappsDataHandler.setCompMetadata(metadata, siteData, packageName, compData.id);
        } else {
            wixappsDataHandler.clearCompMetadata(siteData, packageName, compData.id);
        }

        return dataRequests;
    }

    function createZoomDataRequest(siteData, compData, appService, urlData) {
        const itemId = wixapps.wixappsUrlParser.getPageSubItemId(siteData, urlData);
        if (!itemId) {
            return [];
        }

        const packageName = appService.packageName;
        const data = wixappsDataHandler.getDataByCompId(siteData, packageName, compData.id);

        if (data) {
            if (data[1] === itemId) {
                const videoItems = getVideoItems(siteData, packageName, data);
                return videoThumbDataHandler.handleVideoThumbUrls(videoItems, siteData);
            }
            wixappsDataHandler.clearDataForCompId(siteData, packageName, compData.id);
        }

        const defaultOptions = partsQueryMap[compData.appPartName].defaultOptions;

        const itemPath = [defaultOptions.collectionId, itemId];
        const dataItem = wixappsDataHandler.getDataByPath(siteData, packageName, itemPath);

        if (dataItem) {
            wixappsDataHandler.setDataForCompId(siteData, packageName, compData.id, itemPath);
            return [];
        }

        const params = {
            itemId
        };

        return partsQueryMap[compData.appPartName].method(siteData, compData, appService, params);
    }

    function getVideoItems(siteData, packageName, data) {
        if (_.isArray(data)) {
            if (_.isArray(data[0])) {
                return _(data)
                    .map(extractVideoPostsByRefs.bind(undefined, siteData, packageName))
                    .flattenDeep()
                    .compact()
                    .value();
            }

            return extractVideoPostsByRefs(siteData, packageName, data);
        }
        if (_.isObject(data)) {
            return extractVideoPosts(packageName, data);
        }
        return null;
    }

    function extractVideoPostsByRefs(siteData, packageName, path) {
        const postItem = wixappsDataHandler.getDataByPath(siteData, packageName, path);
        return extractVideoPosts(packageName, postItem, path);
    }

    function extractVideoPosts(packageName, postItem, itemPath) {
        itemPath = itemPath || [];
        const postItemBasePath = wixappsDataHandler.getSiteDataDestination(packageName).concat(['items']).concat(itemPath);

        const type = postItem && postItem._type;

        switch (type) {
            case 'MediaPost':
                return _.map(_.reject(postItem.mediaText.videoList, 'imageSrc'), function (videoItem) {
                    return {item: videoItem, path: postItemBasePath.concat(['mediaText', 'videoList'])};
                });
            case 'VideoPost':
                return !postItem.video.imageSrc && [
                    {item: postItem.video, path: postItemBasePath.concat(['video'])}
                ];
            default:
                return null;
        }
    }

    function onCompRequestFailedInSSR(siteData, packageName, compId) {
        if (!siteData.isInSSR()) {
            return;
        }
        const requestCache = objectUtils.resolvePath(siteData, ['wixapps', packageName, 'requestCache']);
        delete requestCache[compId];
        wixappsDataHandler.setCompFailedRequests(true, siteData, packageName, compId);
    }

    function readItem(siteData, compData, appService, params, urlData, transformationFn) {
        const defaultOptions = partsQueryMap[compData.appPartName].defaultOptions;
        const dataUrl = `${urlUtils.baseUrl(siteData.getExternalBaseUrl())}/apps/lists/1/ReadItem?consistentRead=false`;

        const collectionId = params.collectionId || defaultOptions.collectionId;
        const itemId = params.itemId || defaultOptions.itemId;

        const postData = {
            autoDereferenceLevel: 3,
            collectionId,
            itemId,
            storeId: appService.datastoreId
        };

        let transformFunc = transformationFn ? transformationFn : transformItemResponse;
        transformFunc = transformFunc.bind(undefined, compData.id, collectionId);

        return [
            {
                force: true,
                destination: wixappsDataHandler.getSiteDataDestination(appService.packageName),
                name: appService.packageName,
                url: dataUrl,
                data: postData,
                error: _.partial(onCompRequestFailedInSSR, siteData, appService.packageName, compData.id),
                transformFunc: transformAndSetMetaData.bind(this, transformFunc, siteData, appService.packageName, compData.id),
                timeout: 10
            }
        ];
    }

    function readItemFromUrl(siteData, compData, appService, params, urlData) {
        let request;
        //this will work as long as we won't have item pages in popups
        const primaryRootInfo = siteData.getExistingRootNavigationInfo(siteData.getPrimaryPageId());
        const pageInfo = urlData ? _.assign(primaryRootInfo, urlData) : primaryRootInfo;
        if (pageInfo && pageInfo.pageAdditionalData) {
            if (siteData.isUsingUrlFormat(coreUtils.siteConstants.URL_FORMATS.SLASH) && urlData.format === coreUtils.siteConstants.URL_FORMATS.SLASH) {
                const queryParams = wixapps.wixappsUrlParser.getAppPageParams(siteData, urlData);
                request = query(siteData, compData, appService, queryParams, urlData, transformSingleItemFromQuery);
            } else {
                const readItemParams = _.merge({}, params, {itemId: pageInfo.pageAdditionalData});
                request = readItem(siteData, compData, appService, readItemParams, urlData, transformItemResponseFromUrl);
            }
        } else {
            request = query(siteData, compData, appService, {'limit': 1, 'itemId': '1'}, urlData, transformSingleItemFromQuery);
        }

        return request;
    }

    function query(siteData, compData, appService, params, urlData, transformationFn) { // eslint-disable-line complexity
        const defaultOptions = partsQueryMap[compData.appPartName].defaultOptions;
        const dataUrl = `${urlUtils.baseUrl(siteData.getExternalBaseUrl())}/apps/lists/1/Query?consistentRead=false`;
        const transformItemsFn = transformationFn ? transformationFn : transformItemsResponse;
        const collectionId = params.collectionId || defaultOptions.collectionId;
        const appSpecificFilter = appSpecificFilterMap[collectionId].query;

        const getTotalCount = params.getTotalCount || defaultOptions.getTotalCount;
        const filter = _.merge(params.filter || defaultOptions.filter || {}, appSpecificFilter);
        if (_.isUndefined(filter.draft)) {
            filter.draft = false;
        }
        const sort = params.sort || defaultOptions.sort;
        const skip = parseInt(params.skip || defaultOptions.skip, 10) || 0;
        const limit = parseInt(params.limit || defaultOptions.limit, 10) || null;

        const postData = {
            autoDereferenceLevel: 3,
            collectionId,
            storeId: appService.datastoreId,
            getTotalCount,
            filter,
            sort,
            skip,
            limit
        };

        if (defaultOptions.fields) {
            postData.fields = defaultOptions.fields;
        }

        let queryStartTime;
        if (!siteData.rendererModel.previewMode) {
            queryStartTime = _.now();
        }

        const wrappedTransformItemsFn = function () {
            if (!siteData.rendererModel.previewMode) {
                reportPerformanceBiEvent(siteData, compData, queryStartTime);
            }

            const args = [compData.id, collectionId].concat(Array.prototype.slice.call(arguments));
            return transformItemsFn.apply(undefined, args);
        };

        return [
            {
                force: true,
                destination: wixappsDataHandler.getSiteDataDestination(appService.packageName),
                name: appService.packageName,
                url: dataUrl,
                data: postData,
                error: _.partial(onCompRequestFailedInSSR, siteData, appService.packageName, compData.id),
                transformFunc: transformAndSetMetaData.bind(this, wrappedTransformItemsFn, siteData, appService.packageName, compData.id),
                timeout: 10
            }
        ];
    }

    /**
     *
     * @param {core.SiteData} siteData
     * @param {string} packageName
     * @param {string} compId
     * @returns {utils.Store.requestDescriptor[]} requestDescriptors
     */
    function getVideoThumbRequests(siteData, packageName, compId) {
        const metaData = wixappsDataHandler.getCompMetadata(siteData, packageName, compId);
        const data = wixappsDataHandler.getDataByCompId(siteData, packageName, compId);
        const videoItems = getVideoItems(siteData, packageName, data);
        if (!videoItems || metaData.videos > 0) {
            return [];
        }

        const videoThumbRequests = videoThumbDataHandler.handleVideoThumbUrls(videoItems, siteData);
        const metadata = {videos: videoThumbRequests.length};
        if (videoThumbRequests.length === 0 && wixappsDataHandler.getDescriptor(siteData, packageName)) {
            wixappsDataHandler.clearCompMetadata(siteData, packageName, compId);
            //            metadata.loading = false;
        } else {
            wixappsDataHandler.setCompMetadata(metadata, siteData, packageName, compId);
        }

        return _.map(videoThumbRequests, function (requestDesc) {
            if (requestDesc.transformFunc) {
                const originalTransformFunc = requestDesc.transformFunc;
                requestDesc.transformFunc = function () {
                    let result;
                    if (originalTransformFunc) {
                        result = originalTransformFunc.apply(this, arguments);
                    }
                    const remainingVideoRequests = metaData.videos - 1;
                    const descriptor = wixappsDataHandler.getDescriptor(siteData, packageName);
                    if (remainingVideoRequests === 0 && descriptor) {
                        wixappsDataHandler.clearCompMetadata(siteData, packageName, compId);
                    } else {
                        wixappsDataHandler.setCompMetadata({videos: remainingVideoRequests}, siteData, packageName, compId);
                    }
                    return result;
                };
            }

            return requestDesc;
        });
    }

    function queryFromUrlAndHandleMediaPost(siteData, compData, appService, params, urlData) { // eslint-disable-line complexity
        const DEFAULT_PAGE_SIZE = 10,
            MOBILE_PAGE_SIZE = 3;

        const defaultOptions = partsQueryMap[compData.appPartName].defaultOptions;

        const format = siteData.isMobileView() ? 'Mobile' : '';
        const defaultPageSize = siteData.isMobileView() ? MOBILE_PAGE_SIZE : DEFAULT_PAGE_SIZE;

        const numberOfPostsPerPage = numberOfPostsPerPageGetter.getNumberOfPostsPerPage(compData, format, defaultPageSize) ||
            _.min([defaultOptions.limit, params.limit]);
        params.limit = numberOfPostsPerPage;

        const pageParams = wixapps.wixappsUrlParser.getAppPageParams(siteData, urlData);
        if (!pageParams) {
            // This component is not in AppPage and can't be rendered.
            return [];
        }
        if (pageParams.page && Number(pageParams.page)) {
            params.skip = Number(pageParams.page) * numberOfPostsPerPage;
        }
        if (pageParams.filter && !_.isEmpty(pageParams.filter)) {
            params.filter = _.merge(params.filter || {}, pageParams.filter);
        }
        if (pageParams.categoryNames) {
            params.categoryNames = pageParams.categoryNames;
        }

        return queryAndHandleMediaPost(siteData, compData, appService, params, urlData);
    }

    function queryAndHandleMediaPost(siteData, compData, appService, params, urlData) {
        if (!blogCategories.extendParamsWithBlogCategoryFilter(siteData, params)) { // Are blog categories unready?
            return [];
        }

        return query(siteData, compData, appService, params, urlData, _.partial(transformMediaItemsResponse, _, _, _, _, getLang(siteData)));
    }

    function queryAndHandleMediaPostWithUrllessPagination(siteData, compData, appService, params, urlData) {
        const numberOfPostsPerPage = numberOfPostsPerPageGetter.getNumberOfPostsPerPage(compData);
        const currentPageNumber = appPartCommonDataManager.getAppPartCommonDataItem(compData.id, 'currentPageNumber', 1);
        const overridenParams = _.merge({}, params, {
            getTotalCount: true,
            limit: numberOfPostsPerPage,
            skip: (currentPageNumber - 1) * numberOfPostsPerPage
        });
        return queryAndHandleMediaPost(siteData, compData, appService, overridenParams, urlData);
    }

    function archiveGroupByAndCount(siteData, compData, appService, params, urlData) {
        const groupByAndCountRequest = groupByAndCount(siteData, compData, appService, params, urlData, transformArchiveItemsResponse);
        groupByAndCountRequest[0].callback = function () {
            const compDataRefs = wixappsDataHandler.getDataByCompId(siteData, appService.packageName, compData.id);
            const data = wixappsDataHandler.getDataByPath(siteData, appService.packageName, compDataRefs);
            let selectedItem;
            if (urlData && urlData.pageAdditionalData && urlData.pageAdditionalData.indexOf('Date/') === 0) {
                selectedItem = urlData.pageAdditionalData.replace('Date/', '');
            }
            if (data) {
                _.forEach(data.items, function (item) {
                    item.selected = item.value === selectedItem; // eslint-disable-line santa/no-side-effects
                });
            }
        };
        return groupByAndCountRequest;
    }

    function getTagNamesQuery(siteData, storeId, packageName) {
        const PATH = ['wixapps', 'blog', 'hasTagsRequested'];
        if (packageName !== 'blog' || wixappsDataHandler.getBlogTagNames(siteData) || _.get(siteData, PATH)) {
            return [];
        }

        _.set(siteData, PATH, true);

        const tagsPath = ['wixapps', 'blog', 'tagNames'];
        const dataUrl = `${urlUtils.baseUrl(siteData.getExternalBaseUrl())}/apps/lists/1/GroupByAndCount?consistentRead=false`;
        const tagsRequest = {
            url: dataUrl,
            data: {
                collectionId: 'Posts',
                field: 'tags',
                filter: {
                    'scheduled.iso': {
                        '$not': {
                            '$gt': '$now'
                        }
                    },
                    'draft': false,
                    'deleted': {'$ne': true}
                },
                type: 'Post',
                storeId
            },
            force: true,
            destination: tagsPath,
            name: packageName,
            transformFunc(response) {
                return Object.keys(response.payload || {});
            }
        };
        return [tagsRequest];
    }

    function groupByAndCount(siteData, compData, appService, params, urlData, transformationFn) { // eslint-disable-line complexity
        const defaultOptions = partsQueryMap[compData.appPartName].defaultOptions;
        const dataUrl = `${urlUtils.baseUrl(siteData.getExternalBaseUrl())}/apps/lists/1/GroupByAndCount?consistentRead=false`;

        const collectionId = params.collectionId || defaultOptions.collectionId;
        const appSpecificFilter = appSpecificFilterMap[collectionId].groupByAndCount;
        const field = params.field || defaultOptions.field;
        const filter = _.merge(params.filter || defaultOptions.filter || {}, appSpecificFilter);
        const project = params.project || defaultOptions.project;
        const type = params.type || defaultOptions.type;

        const postData = {
            collectionId,
            storeId: appService.datastoreId,
            field,
            filter,
            project,
            type
        };

        const options = {
            sort: params.sort || defaultOptions.sort,
            limit: params.limit || defaultOptions.limit,
            normalizeTo: params.normalizeTo || defaultOptions.normalizeTo
        };
        const transformGroupByFn = transformationFn ? transformationFn : transformGroupByResponse;

        const queryStartTime = _.now();

        const wrappedTransformGroupByFn = function () {
            reportPerformanceBiEvent(siteData, compData, queryStartTime);

            const args = [compData.id, collectionId, generateGroupByKey.bind(undefined, postData), options].concat(Array.prototype.slice.call(arguments));
            return transformGroupByFn.apply(undefined, args);
        };

        return [
            {
                force: true,
                destination: wixappsDataHandler.getSiteDataDestination(appService.packageName),
                name: appService.packageName,
                url: dataUrl,
                data: postData,
                error: _.partial(onCompRequestFailedInSSR, siteData, appService.packageName, compData.id),
                transformFunc: transformAndSetMetaData.bind(this, wrappedTransformGroupByFn, siteData, appService.packageName, compData.id),
                timeout: 10
            }
        ];
    }


    /**
     *
     * @param {core.SiteData} siteData
     * @param {object} compInfo
     * @returns {utils.Store.requestDescriptor|null} requestDescriptors
     */
    function getDescriptorRequestIfNeeded(siteData, compInfo) {
        const appInnerId = compInfo.data.appInnerID;
        const appService = siteData.getClientSpecMapEntry(appInnerId);
        const packageName = appService.packageName;
        const descriptor = wixappsDataHandler.getDescriptor(siteData, appService.packageName);
        const descriptorRequests = getMap(siteData, 'descriptorRequests');

        if (!descriptor) {
            descriptorRequests[packageName] = descriptorRequests[packageName] || [];
            descriptorRequests[packageName].push(compInfo.data.id);
            wixappsDataHandler.setCompMetadata({loading: true}, siteData, packageName, compInfo.data.id);
            if (descriptorRequests[packageName].length > 1) {
                return null;
            }
            const destination = wixappsDataHandler.getSiteDataDestination(packageName).concat(['descriptor']);
            const descriptorUrl = descriptorUtils.getDescriptorUrl(siteData, packageName, false);
            return {
                urls: [descriptorUrl, descriptorUtils.getDescriptorUrl(siteData, packageName, true)],
                destination,
                name: packageName,
                transformFunc(descriptorObj, currentValue) {
                    if (_.has(descriptorObj, 'generatedViews')) {
                        descriptorObj.views = descriptorObj.views.concat(descriptorObj.generatedViews);
                        delete descriptorObj.generatedViews;
                    }
                    _.assign(currentValue, descriptorObj); // eslint-disable-line santa/no-side-effects

                    wixappsDataHandler.setPackageMetadata({}, siteData, packageName);
                    _.forEach(descriptorRequests[packageName], function (compId) {
                        const metadata = wixappsDataHandler.getCompMetadata(siteData, packageName, compId);
                        if (metadata.dataReady) {
                            wixappsDataHandler.clearCompMetadata(siteData, packageName, compId);
                        }
                    });

                    descriptorRequests[packageName] = [];

                    return currentValue;
                },
                timeout: 10,
                error() {
                    _.forEach(descriptorRequests[packageName], function (compId) {
                        wixappsDataHandler.setCompMetadata({hasError: -1}, siteData, packageName, compId);
                    });
                    descriptorRequests[packageName] = [];
                    onCompRequestFailedInSSR(siteData, packageName, compInfo.data.id);
                },
                onUrlRequestFailure(url, errName, err) {
                    if (url === descriptorUrl) {
                        const errorData = _.clone(wixappsLogger.errors.REQUEST_FAILED);
                        errorData.desc = errName ? errName : errorData.desc;
                        errorData.errorCode = err ? err : errorData.errorCode;
                        wixappsLogger.reportError(siteData, errorData);
                    }
                }
            };
        }
        return null;
    }

    // TODO: move this function to dataFixer when it will be plug-able from different packages.
    function migrateViewsWithProxyName(compInfo) {
        const viewName = compInfo.data.viewName;
        if (wixapps.proxyFactory.isValidProxyName(viewName)) {
            compInfo.data.viewName = `${viewName}View`;
            _(compInfo.data.appLogicCustomizations)
                .filter({view: viewName})
                .forEach(function (customization) {
                    customization.view = compInfo.data.viewName; // eslint-disable-line santa/no-side-effects
                });
        }
    }

    function getItemsRequests(getEcomRequests, getWixAppRequests, siteData, compData, packageName, appService, urlData) { // eslint-disable-line complexity
        const metadata = wixappsDataHandler.getCompMetadata(siteData, packageName, compData.id);
        if (metadata.dataReady) {
            return [];
        }

        let requests;
        try {
            switch (packageName) {
                case 'ecommerce':
                    requests = getEcomRequests(siteData, compData, appService, urlData);
                    break;
                default:
                    requests = getWixAppRequests(siteData, compData, appService, urlData);
                    break;
            }
        } catch (e) {
            coreUtils.log.error(`appPartDataRequirementsChecker Error: ${e}`);
            requests = [];
        }

        if (requests.length === 0) {
            const descriptor = wixappsDataHandler.getDescriptor(siteData, packageName);
            if (descriptor) {
                wixappsDataHandler.clearCompMetadata(siteData, packageName, compData.id);
            } else {
                const dataPath = wixappsDataHandler.getDataByCompId(siteData, packageName, compData.id);
                if (!dataPath || dataPath && wixappsDataHandler.getDataByPath(siteData, packageName, dataPath)) { // eslint-disable-line no-mixed-operators
                    wixappsDataHandler.setCompMetadata({dataReady: true}, siteData, packageName, compData.id);
                }
            }
        }

        return requests;
    }

    function getAppPartRequests(getEcomRequests, getWixAppRequests, disableTimeout, siteData, compInfo, urlData) { // eslint-disable-line complexity
        const appInnerId = compInfo.data.appInnerID;
        const appService = siteData.getClientSpecMapEntry(appInnerId);
        if (!appService || !appService.packageName) {
            return [];
        }
        const packageName = appService.packageName;

        let requests = getVideoThumbRequests(siteData, packageName, compInfo.data.id);
        const metadata = wixappsDataHandler.getCompMetadata(siteData, packageName, compInfo.data.id);
        const shouldStopCreatingRequests = wixappsDataHandler.didCompHadFailedRequests(siteData, packageName, compInfo.data.id) && siteData.isInSSR();
        if (metadata.hasError || (metadata.loading || shouldStopCreatingRequests) && !requests.length) { // eslint-disable-line no-mixed-operators
            return [];
        }

        migrateViewsWithProxyName(compInfo);
        const descriptorRequest = getDescriptorRequestIfNeeded(siteData, compInfo);
        if (descriptorRequest) {
            requests.push(descriptorRequest);
        }

        requests = requests.concat(blogCategories.queryBlogCategories(siteData, compInfo.data, appService));
        requests = requests.concat(getItemsRequests(getEcomRequests, getWixAppRequests, siteData, compInfo.data, packageName, appService, urlData));
        requests = requests.concat(getTagNamesQuery(siteData, appService.datastoreId, packageName));

        if (disableTimeout) {
            _.forEach(requests, function (request) {
                delete request.timeout;
            });
        }

        _.forEach(requests, function (request) {
            request.error = request.error || function (error, res) { // eslint-disable-line santa/no-side-effects
                wixappsDataHandler.setCompMetadata({hasError: res || -1}, siteData, packageName, compInfo.data.id);
            };
        });

        return requests;
    }

    componentsCore.dataRequirementsCheckerRegistrar.registerCheckerForCompType('Zoom:AppPart', getAppPartRequests.bind(this, ecomDataRequirementsChecker.checkZoomDataRequirements, createZoomDataRequest, true));

    componentsCore.dataRequirementsCheckerRegistrar.registerCheckerForAllCompsOfType('wixapps.integration.components.AppPart', function (siteData, compInfos, urlData) {
        const shouldDisableTimeout = isTimeoutDisabled(siteData);
        const requests = _.reduce(compInfos, function (compsRequests, compInfo) {
            const compRequests = getRequestsForAppPart(siteData, compInfo, urlData, shouldDisableTimeout);
            return compsRequests.concat(compRequests);
        }, []);
        return batchableListsRequestsMerger.mergeBatchableListsRequestsIfAny(siteData, requests, shouldDisableTimeout);
    });

    function getRequestsForAppPart(siteData, compInfo, urlData, shouldDisableTimeout) {
        return getAppPartRequests(
            ecomDataRequirementsChecker.checkDataRequirements, createPartDataRequest, shouldDisableTimeout,
            siteData, compInfo, urlData
        );
    }
});
