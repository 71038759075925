define(['coreUtils', 'wixappsCore', 'wixappsClassics/util/numberOfPostsPerPageGetter'], function (coreUtils, /** wixappsCore */ wixapps, numberOfPostsPerPageGetter) {
    'use strict';

    const logicFactory = wixapps.logicFactory;
    const DEFAULT_PAGE_SIZE = 10,
        MOBILE_PAGE_SIZE = 3;

    const blogAppPartNames = coreUtils.blogAppPartNames;

    /**
     * @class wixappsClassics.ListFromPageLogic
     * @param {wixappsClassics.appPartApi} partApi
     * @constructor
     */
    function ListFromPageLogic(partApi) {
        this.partApi = partApi;
    }

    ListFromPageLogic.prototype = {
        getViewVars() {
            const partExtraData = this.partApi.getCompExtraData();
            const format = this.partApi.getFormatName();
            const defaultPageSize = this.partApi.isMobileView() ? MOBILE_PAGE_SIZE : DEFAULT_PAGE_SIZE;

            const pageSize = numberOfPostsPerPageGetter.getNumberOfPostsPerPage(this.partApi.getPartData(), format, defaultPageSize) || DEFAULT_PAGE_SIZE;

            const pageCount = Math.ceil(partExtraData.totalCount / pageSize);
            const appPageParams = this.partApi.getAppPageParams();
            const currentPage = appPageParams && Number(appPageParams.page) || 0; // eslint-disable-line no-mixed-operators

            return {
                pageCount,
                'pageNum': currentPage,
                'hasPrev': currentPage > 0,
                'hasNext': currentPage < pageCount - 1
            };
        },
        shouldResetContext(currentLogicVars) {
            const currentViewVars = this.getViewVars();
            return currentLogicVars.pageNum !== currentViewVars.pageNum;
        }
    };

    logicFactory.register(blogAppPartNames.FEED, ListFromPageLogic);
});
