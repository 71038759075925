define(['wixappsClassics/blog/utils', 'wixappsClassics/blog/blogSettings'], function (blogUtils, blogSettings) {
    'use strict';
    function translateToBlogLocale(translationKey) {
        const settings = blogSettings.get(this.siteData);
        const locale = settings.locale;

        return blogUtils.translate(locale, translationKey);
    }

    return {
        translateToBlogLocale
    };
});
