define([
    'lodash',
    'wixappsClassics/ecommerce/util/ecomRequestBuilder',
    'wixappsClassics/ecommerce/util/ecomDataUtils'], function (_, ecomRequestBuilder, ecomDataUtils) {
    'use strict';
    function sendRequest(siteData, params, requestMetadata, onSuccessCallback, onFailCallback) {
        requestMetadata = requestMetadata || {};
        requestMetadata.onError = onFailCallback;
        const store = siteData.store;
        const request = ecomRequestBuilder.buildRequest(siteData, null,
            ecomDataUtils.packageName, params, requestMetadata);

        delete request.timeout;
        store.loadBatch([request], onSuccessCallback);
    }

    return {
        sendRequest
    };
});
