define([
    'lodash',
    'coreUtils',
    'wixappsCore',
    'wixappsClassics/core/data/converters/mediaPostConverter',
    'wixappsClassics/core/transformAndSetMetaData'
], function (
    _,
    coreUtils,
    wixappsCore,
    mediaPostConverter,
    transformAndSetMetaData
) {
    'use strict';

    const urlUtils = coreUtils.urlUtils;
    const wixappsDataHandler = wixappsCore.wixappsDataHandler;

    function queryBlogCategories(siteData, compData, appService) {
        if (appService.packageName !== 'blog') {
            return [];
        }

        const PATH = ['wixapps', 'blog', 'hasCategoriesRequested'];

        if (_.get(siteData, PATH)) {
            return [];
        }

        _.set(siteData, PATH, true);

        const url = `${urlUtils.baseUrl(siteData.getExternalBaseUrl())}/apps/lists/1/Query?consistentRead=false`;

        const data = {
            collectionId: 'Settings',
            filter: {_iid: 'categories'},
            storeId: appService.datastoreId
        };

        const transformFunc = function (responseData, currentValue) {
            const categories = _.get(responseData.payload, 'items[0].categories');
            if (categories) {
                const orderedCategories = [];
                const categoryById = {};
                _.forEach(categories, function (category) {
                    orderedCategories.push(category);
                    categoryById[category.id] = category;
                    _.forEach(category.subcategories, function (subcategory) {
                        orderedCategories.push(subcategory);
                        categoryById[subcategory.id] = subcategory;
                    });
                });

                currentValue.categories = {
                    categories,
                    orderedCategories,
                    categoryById
                };
            } else {
                currentValue.categories = {};
            }
            mediaPostConverter.resolveCategories(currentValue);
            return currentValue;
        };

        return [{
            force: true,
            destination: wixappsDataHandler.getSiteDataDestination(appService.packageName),
            name: appService.packageName,
            url,
            data,
            transformFunc: transformAndSetMetaData.bind(this, transformFunc, siteData, appService.packageName, compData.id),
            timeout: 10
        }];
    }

    function getCategoryIds(category) {
        const subcategoryIds = _.map(category.subcategories, 'id');
        return [category.id].concat(subcategoryIds);
    }

    function extendParamsWithBlogCategoryFilter(siteData, params) {
        const categoryNames = params.categoryNames && JSON.parse(params.categoryNames);
        if (categoryNames) {
            const areCategoriesReady = wixappsDataHandler.getBlogCategories(siteData);
            if (!areCategoriesReady) {
                return false;
            }

            let categoryIds = [];
            _.forEach(categoryNames, function (name) {
                const category = wixappsDataHandler.getBlogCategoryByName(siteData, name);
                if (category) {
                    categoryIds = categoryIds.concat(getCategoryIds(category));
                }
            });

            if (!params.filter) {
                params.filter = {};
            }
            params.filter.categoryIds = {$in: categoryIds};
        }

        return true;
    }

    function queryBlogCategoryPostCounts(siteData, compData, appService) { // eslint-disable-line complexity
        const categoryStore = wixappsDataHandler.getBlogCategories(siteData);

        if (!categoryStore) { // Are categories unready?
            return [];
        }

        if (!categoryStore.categoriesWithUnresolvedPostCounts) { // Is post counting unstarted?
            categoryStore.categoriesWithUnresolvedPostCounts =
                categoryStore.orderedCategories ? _.cloneDeep(categoryStore.orderedCategories) : [];
            categoryStore.postCountById = {};
        } else if (categoryStore.isPostCountingInProgress) {
            return [];
        }

        const packageName = appService.packageName; // Actually it's always "blog".

        const compId = compData.id;

        if (categoryStore.categoriesWithUnresolvedPostCounts.length === 0) { // Is post counting complete?
            const DATA_PATH_FRAGMENT = 'categoriesWithPostCounts';
            const packageData = wixappsDataHandler.getPackageData(siteData, packageName);

            // Ensure that categories with post counts are created.
            const DATA_PATH = ['items', DATA_PATH_FRAGMENT];
            if (!_.get(packageData, DATA_PATH)) {
                const categoriesWithPostCounts = _.cloneDeep(categoryStore.categories) || [];

                const resolvePostCount = function (category) {
                    category.postCount = categoryStore.postCountById[category.id]; // eslint-disable-line santa/no-side-effects
                };

                _.forEach(categoriesWithPostCounts, function (category) {
                    resolvePostCount(category);
                    _.forEach(category.subcategories, resolvePostCount);
                });

                _.set(packageData, DATA_PATH, categoriesWithPostCounts);
            }

            // Use the categories with post counts as data for the component.
            packageData[compId] = [DATA_PATH_FRAGMENT];

            wixappsDataHandler.setPackageData(siteData, packageName, packageData);

            return [];
        }

        categoryStore.isPostCountingInProgress = true;

        const MAX_OPERATIONS_PER_BATCH = 24;
        const categoriesToBeResolved = categoryStore.categoriesWithUnresolvedPostCounts.splice(0, MAX_OPERATIONS_PER_BATCH);
        // Batch request may be quite heavy so that a limit for number of operations is used to minimize chance of
        // catching timeout.

        // Query post count for each category.
        const batchOperations = _.map(categoriesToBeResolved, function (category) {
            return {
                name: 'Query',
                params: {
                    collectionId: 'Posts',
                    fields: [''], // Avoid returning post data - the response will contain only meta fields (like _iid).
                    filter: {
                        categoryIds: {$in: getCategoryIds(category)},
                        'date.iso': {$lte: '$now'}, // Don't count scheduled posts.
                        deleted: {$ne: true}, // Don't count deleted posts.
                        draft: {$ne: true} // Don't count draft posts.
                    },
                    getTotalCount: true,
                    limit: 1, // A smaller number has no effect.
                    storeId: appService.datastoreId
                }
            };
        });

        function transformResponse(responseData, currentValue) {
            // The category store from above can be obsolete if page JSON revision changes.
            const currentCategoryStore = wixappsDataHandler.getBlogCategoriesFromPackageData(currentValue);
            if (_.get(currentCategoryStore, 'isPostCountingInProgress')) {
                delete currentCategoryStore.isPostCountingInProgress;
                _.forEach(responseData.payload.results, function (result, index) {
                    const category = categoriesToBeResolved[index];
                    currentCategoryStore.postCountById[category.id] = result.payload.totalCount;
                });
            }

            return currentValue;
        }

        return [{
            force: true,
            destination: wixappsDataHandler.getSiteDataDestination(packageName),
            name: packageName,
            url: `${urlUtils.baseUrl(siteData.getExternalBaseUrl())}/apps/lists/1/Batch?consistentRead=false`,
            data: {operations: batchOperations},
            transformFunc: transformAndSetMetaData.bind(this, transformResponse, siteData, packageName, compId),
            timeout: 10
        }];
    }

    return {
        queryBlogCategories,
        extendParamsWithBlogCategoryFilter,
        queryBlogCategoryPostCounts
    };
});
