define(['wixappsCore', 'coreUtils', 'lodash'], function (wixapps, coreUtils, _) {
    'use strict';

    const logicFactory = wixapps.logicFactory;
    const blogAppPartNames = coreUtils.blogAppPartNames;

    /**
     * @class wixappsClassics.TwoLevelCategoryLogic
     * @param partApi
     * @constructor
     */
    function RelatedPostsLogic(partApi) {
        this.partApi = partApi;
    }

    RelatedPostsLogic.prototype = {
        getViewVars() {
            return {
                isEditMode: false
            };
        },
        isHeightResizable() {
            const compData = this.partApi.getRootDataItemRef();
            return !_.isEmpty(compData);
        }
    };

    logicFactory.register(blogAppPartNames.RELATED_POSTS, RelatedPostsLogic);
});
