define(['lodash', 'wixappsCore'], function (_, /** wixappsCore */wixapps) {
    'use strict';

    function updateQuantity(evt) {
        const onFailCallback = createOnFailCallback(this.partApi, 2027);
        const product = evt.params.product;
        product.quantity = evt.payload.oldValue || evt.payload;
        this.partApi.updateProduct(product, onFailCallback);
    }

    function createOnFailCallback(partApi, code) {
        return function () {
            const errorParams = {
                code
            };
            partApi.ecomShowMessage(errorParams);
        };
    }

    /**
     * @class ecom.ShoppingCartLogic
     * @param partApi
     * @constructor
     */
    function ShoppingCartLogic(partApi) {
        this.partApi = partApi;
    }

    ShoppingCartLogic.prototype = {
        removeProduct(evt) {
            const product = evt.params.product;
            const onFailCallback = createOnFailCallback(this.partApi, 2028);
            this.partApi.removeProduct(product, onFailCallback);
        },
        handleQuantityChanged: _.debounce(updateQuantity, 400),
        handleInvalidQuantity(evt) {
            if (evt.payload.invalidValue < evt.payload.minValue) {
                return;
            }

            const id = 'batatot';

            const skin = this.partApi.isMobileView() ? 'wysiwyg.viewer.skins.MobileMessageViewSkin' : 'wysiwyg.viewer.skins.MessageViewSkin';
            const structure = {
                componentType: 'wysiwyg.viewer.components.MessageView',
                skin,
                type: 'Component',
                id
            };

            const partApi = this.partApi;
            const props = {
                compProp: {
                    title: 'Insufficient Stock',
                    description: 'You reached the maximum available quantity for this product',
                    onCloseCallback() {
                        partApi.deleteComponent(id);
                    }
                }
            };
            partApi.addComponent(id, structure, props);
        },

        isReady() {
            return !this.partApi.getPackageMetadata().updatingCart;
        }
    };

    wixapps.logicFactory.register('adbeffec-c7df-4908-acd0-cdd23155a817', ShoppingCartLogic);

    return ShoppingCartLogic;
});
