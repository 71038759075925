define(['lodash',
    'wixappsCore',
    'wixappsClassics/ecommerce/data/checkoutManager',
    'wixappsClassics/ecommerce/logics/ShoppingCartLogic'
], function (_, /** wixappsCore */wixapps, checkoutManager, ShoppingCartLogic) {
    'use strict';

    const logicFactory = wixapps.logicFactory;

    function getRegions(countryId) {
        const shippingData = getShippingData(this.partApi);
        const countries = shippingData.countries.items;
        const countryDefinition = _.find(countries, {value: countryId});
        return countryDefinition && countryDefinition.regions;
    }

    function getShippingData(partApi) {
        return partApi.getEcomCart().fees.destination;
    }

    function setRegions(partApi, regions) {
        partApi.setCartItemDataAndUpdate(['fees', 'destination', 'regions'], regions);
    }

    /**
     * @class ecom.EcomJoinedCartLogic
     * @param partApi
     * @constructor
     */
    function EcomJoinedCartLogic(partApi) {
        ShoppingCartLogic.call(this, partApi);
    }

    function setCountriesValidity(partApi) {
        const shippingData = getShippingData(partApi);

        shippingData.countries.valid = !!this._selectedCountry;
        partApi.setCartItemDataAndUpdate(['fees', 'destination'], shippingData);
    }

    function canClickCheckout(partApi) {
        const shippingData = getShippingData(partApi);
        const canClick = shippingData && (!shippingData.shippable || shippingData.name);

        if (!canClick) {
            setCountriesValidity.call(this, partApi);
            if (this._selectedCountry) {
                const regions = getRegions.call(this, this._selectedCountry);
                if (regions) {
                    regions.valid = regions.selectedValue !== -1;
                    setRegions(partApi, regions);
                }
            }
        }

        return canClick;
    }

    function onShippingFailed(errorParams) {
        this.partApi.ecomShowMessage(errorParams);
    }

    EcomJoinedCartLogic.prototype = _.assign(Object.create(ShoppingCartLogic.prototype), {
        getViewVars() {
            return {couponValid: true, toggleState: 'off'};
        },
        'changeDestination'() {
            this._selectedCountry = null;
            this.partApi.setShipping(null, null, false, null, onShippingFailed.bind(this, {code: 2032}));
            const shippingData = getShippingData(this.partApi);
            if (!shippingData.countries) {
                this.partApi.getShipping(onShippingFailed.bind(this, {code: 2033}));
            }
        },

        'clear-coupon'() {
            this.partApi.clearCoupon();
        },

        'set-coupon'(evt) {
            const self = this;
            this.partApi.setCoupon(evt.params.couponCode, function (error) {
                const errorCode = error ? error.code : 0;
                const errorMessage = `${wixapps.localizer.localize('@ECOM_COUPON_API_FAILED_ERR_DESC@', self.partApi.getLocalizationBundle())} (${errorCode}).`;
                self.partApi.setVar('couponValid', false);
                self.partApi.setCartItemDataAndUpdate(['coupon', 'validationMessage'], errorMessage);
            });
        },

        'clear-message'() {
            this.partApi.setVar('couponValid', true);
            this.partApi.setCartItemDataAndUpdate(['coupon', 'validationMessage'], '');
        },

        'click-checkout'() {
            if (canClickCheckout.call(this, this.partApi)) {
                checkoutManager.handleCheckout(this.partApi);
            }
        },
        'countrySelected'(evt) {
            const self = this;
            const countryId = evt.payload.value;
            const regions = getRegions.call(this, countryId);
            this._selectedCountry = countryId; //set country selected
            setCountriesValidity.call(this, this.partApi);
            const setCouponToggle = function () {
                self.partApi.setVar('toggleState', 'off');
            };
            if (_.get(regions, 'items.length', 0) > 0) {
                setRegions(this.partApi, regions);
                return;
            }
            this.partApi.setShipping(countryId, undefined, true, setCouponToggle, onShippingFailed.bind(this, {code: 2032}));
        },
        'regionSelected'(evt) {
            const self = this;
            const regionId = evt.payload.value;
            const setCouponToggle = function () {
                self.partApi.setVar('toggleState', 'off');
            };
            this.partApi.setShipping(this._selectedCountry, regionId, true, setCouponToggle, onShippingFailed.bind(this, {code: 2032}));
        },

        getUserCustomizations(customizations) {
            return _.map(customizations, function (item) {
                if (item.fieldId === 'checkoutButtonLink' && item.value === '') {
                    item.value = 'CHECKOUT NOW';
                }
                return item;
            });
        }
    });

    logicFactory.register('5fca0e8b-a33c-4c18-b8eb-da50d7f31e4a', EcomJoinedCartLogic);
});
