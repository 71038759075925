define(['lodash', 'coreUtils', 'wixappsCore'
], function (_, coreUtils, /** wixappsCore */wixapps) {
    'use strict';



    const logicFactory = wixapps.logicFactory;

    const blogAppPartNames = coreUtils.blogAppPartNames;

    /**
     * @class ecom.AddToCartButtonLogic
     * @param partApi
     * @constructor
     */
    function ArchiveLogic(partApi) {
        this.partApi = partApi;
    }

    ArchiveLogic.prototype = {
        'comboBoxSelectionChanged'(evt) {
            const blogPage = this.partApi.getBlogAppPage();
            const navigationInfo = {
                pageId: blogPage.id,
                pageAdditionalData: evt.payload.value ? `Date/${evt.payload.value}` : ''
            };

            this.partApi.navigateToPage(navigationInfo);
        },
        'listOptionClicked'() {
            // handled in the all mighty appPartDataRequirementsChecker
        }
    };

    logicFactory.register(blogAppPartNames.ARCHIVE, ArchiveLogic);
});
