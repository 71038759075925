define([
    'santa-components',
    'lodash',
    'componentsCore',
    'prop-types',
    'coreUtils',
    'wixappsClassics/ecommerce/components/colorOption',
    'wixappsClassics/ecommerce/components/mobileColorOption',
    'wixappsClassics/ecommerce/components/mobileTextOption',
    'wixappsClassics/ecommerce/components/textOption'
], function (
    santaComponents,
    _,
    componentsCore,
    PropTypes,
    coreUtils,
    colorOption,
    mobileColorOption,
    mobileTextOption,
    textOption
) {
    'use strict';

    function selectItem(itemData, event, domID) {
        if (this.props.onSelectionChange) {
            event.type = 'selectionChanged';
            event.payload = itemData;
            this.props.onSelectionChange(event, domID);
        }
    }
    /**
     * @class components.OptionsListInput
     * @extends {core.skinBasedComp}
     * @property {{$validity: string}} state
     */
    return {
        displayName: 'OptionsListInput',
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],

        propType: _.defaults({
            itemClassName: PropTypes.string.isRequired,
            itemSkin: PropTypes.string.isRequired,
            selectedItem: PropTypes.object,
            valid: PropTypes.boolean,

            // Events
            onSelectionChange: PropTypes.function
        },
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(colorOption),
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(mobileColorOption),
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(mobileTextOption),
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(textOption)
        ),

        getInitialState() {
            return this.getCssState(this.props);
        },

        getCssState(props) {
            return {
                '$validity': props.valid === false ? 'invalid' : 'valid'
            };
        },

        componentWillReceiveProps(props) {
            if (props.valid !== this.props.valid) {
                this.setState(this.getCssState(props));
            }
        },

        getSkinProperties() {
            const children = _.map(this.props.compData.options, function (itemData, index) {
                const extraProperties = {
                    selected: this.props.selectedItem === itemData,
                    onClick: selectItem.bind(this, itemData),
                    id: this.props.id + index,
                    ref: index
                };
                return this.createChildComponent(
                    itemData,
                    this.props.itemClassName, { //TODO: this should be in skin exports
                        skin: this.props.itemSkin,
                        styleId: coreUtils.santaTypes.shortenStyleId(this.props.itemSkin)
                    },
                    extraProperties);
            }.bind(this));

            return {
                '': {
                    children
                }
            };
        }
    };
});
