define(['coreUtils'], function (coreUtils) {
    'use strict';

    function resolveImageData(data, serviceTopology, packageName) {
        const url = data.src;
        const scriptsLocationMap = serviceTopology.scriptsLocationMap;
        if (!scriptsLocationMap || !url) {
            return data;
        }
        const appBasedPath = packageName === 'ecommerce' ?
            scriptsLocationMap.ecommerce :
            `${scriptsLocationMap.wixapps}/javascript/wixapps/apps/${packageName}/`;

        const match = url.match(/^(http:\/\/)?(images\/.*)/);
        data.src = match ? coreUtils.urlUtils.joinURL(appBasedPath, match[2]) : url;

        return data;
    }

    return {
        resolveImageData
    };
});
