define([
    'componentsCore',
    'wixappsCore',
    'wixappsClassics/ecommerce/proxies/numericStepperProxy',
    'wixappsClassics/ecommerce/proxies/optionsListInputProxy',
    'wixappsClassics/ecommerce/proxies/selectOptionsListProxy',
    'wixappsClassics/ecommerce/proxies/tableProxy',
    'wixappsClassics/ecommerce/aspects/ecomCheckoutAspect',
    'wixappsClassics/ecommerce/aspects/ecomDialogAspect',

    'wixappsClassics/ecommerce/components/ecomCheckoutMessageDialog',
    'wixappsClassics/ecommerce/ecommerceSantaTypes',
    'wixappsClassics/ecommerce/components/colorOption',
    'wixappsClassics/ecommerce/components/textOption',
    'wixappsClassics/ecommerce/components/mobileColorOption',
    'wixappsClassics/ecommerce/components/mobileTextOption',
    'wixappsClassics/ecommerce/components/numericStepper',
    'wixappsClassics/ecommerce/components/optionsListInput',
    'wixappsClassics/ecommerce/components/selectOptionsList',

    'wixappsClassics/ecommerce/logics/addToCartButtonLogic',
    'wixappsClassics/ecommerce/logics/CheckoutButtonLogic',
    'wixappsClassics/ecommerce/logics/EcomJoinedCartLogic',
    'wixappsClassics/ecommerce/logics/ShoppingCartLogic',
    'wixappsClassics/ecommerce/logics/viewCartLogic',
    'wixappsClassics/ecommerce/logics/productPageLogic',
    'wixappsClassics/ecommerce/logics/feedbackMessageLogic',
    'wixappsClassics/ecommerce/logics/productListLogic'
], function (
    componentsCore,
    /** wixappsCore */ wixapps,
    numericStepperProxy,
    optionsListInputProxy,
    selectOptionsListProxy,
    table,
    ecomCheckoutAspect,
    ecomDialogAspect,
    ecomCheckoutMessageDialog,
    ecommerceSantaTypes,
    colorOption,
    textOption,
    mobileColorOption,
    mobileTextOption,
    numericStepper,
    optionsListInput,
    selectOptionsList
) {
    'use strict';

    wixapps.proxyFactory.register('NumericStepper', numericStepperProxy);
    wixapps.proxyFactory.register('OptionsList', optionsListInputProxy);
    wixapps.proxyFactory.register('SelectOptionsList', selectOptionsListProxy);
    wixapps.proxyFactory.register('Table', table);
    componentsCore.siteAspectsRegistry.registerSiteAspect('ecomCheckout', ecomCheckoutAspect);
    componentsCore.siteAspectsRegistry.registerSiteAspect('ecomDialog', ecomDialogAspect);

    componentsCore.compRegistrar
        .register('ecommerce.viewer.dialogs.EcomCheckoutMessageDialog', ecomCheckoutMessageDialog)
        .register('wysiwyg.viewer.components.inputs.ColorOption', colorOption)
        .register('wysiwyg.viewer.components.inputs.TextOption', textOption)
        .register('ecommerce.integration.components.MobileColorOption', mobileColorOption)
        .register('ecommerce.integration.components.MobileTextOption', mobileTextOption)
        .register('wysiwyg.common.components.NumericStepper', numericStepper)
        .register('wysiwyg.common.components.inputs.OptionsListInput', optionsListInput)
        .register('wysiwyg.common.components.inputs.SelectOptionsList', selectOptionsList);

    return {
        ecommerceSantaTypes
    };
});
