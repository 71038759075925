define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';

    const dataById = {};
    coreUtils.sessionFreezer.freeze(dataById);

    return {
        getAppPartCommonDataItem(id, key, defaultValue) {
            return _.get(dataById, [id, key], defaultValue);
        },

        setAppPartCommonDataItem(id, key, value) {
            return _.set(dataById, [id, key], value);
        },

        removeAppPartCommonData(id) {
            delete dataById[id];
        }
    };
});
