define(['lodash'], function (_) {
    'use strict';

    function getApplicationDataStore(siteData) {
        if (!siteData.wixapps) {
            siteData.wixapps = {};
        }
        if (!siteData.wixapps.ecommerce) {
            siteData.wixapps.ecommerce = {};
        }
        if (!siteData.wixapps.ecommerce.items) {
            siteData.wixapps.ecommerce.items = {};
        }
        return siteData.wixapps.ecommerce;
    }

    function clearApplicationDataStore(siteData) {
        siteData.wixapps.ecommerce = _.pick(siteData.wixapps.ecommerce, ['descriptor']);
    }

    function handleFailedRequestInClientAfterSSR(siteData, wixappsDataHandler, packageName, compData) {
        if (!siteData.isInSSR() && wixappsDataHandler.didCompHadFailedRequests(siteData, packageName, compData.id)) {
            _.defer(function () {
                wixappsDataHandler.setCompFailedRequests(false, siteData, packageName, compData.id);
                wixappsDataHandler.setCompMetadata({loading: false}, siteData, packageName, compData.id);
            }, 10);
        }
    }

    /**
     * @class ecomDataUtils
     */
    return {
        packageName: 'ecommerce',
        getApplicationDataStore,
        handleFailedRequestInClientAfterSSR,
        clearApplicationDataStore
    };
});
