define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';

    const urlUtils = coreUtils.urlUtils;

    return {
        mergeBatchableListsRequestsIfAny(siteData, requests, isTimeoutDisabled) {
            if (requests.length < 2) { // no need to batch
                return requests;
            }
            const batchablePartition = _.partition(requests, requestIsBatchable);
            const batchableRequests = batchablePartition[0];
            const mergedBatchableRequestsIfAny = _.isEmpty(batchableRequests) ? [] :
                mergeBatchableRequests(siteData, batchableRequests, isTimeoutDisabled);

            return batchablePartition[1].concat(mergedBatchableRequestsIfAny);
        }
    };

    function requestIsBatchable(request) {
        return destinationIsInWixapps(request.destination) && urlIsBatchableOperationUrl(request.url);
    }

    function destinationIsInWixapps(destination) {
        return _.startsWith(destination, getWixappsDestination());
    }

    function urlIsBatchableOperationUrl(url) {
        const pathname = getPathnameFromUrl(url);
        const pathnameRegExp = getRegExpForMatchingBatchableOperationNameInPathname();
        return pathnameRegExp.test(pathname);
    }

    function mergeBatchableRequests(siteData, batchableRequests, isTimeoutDisabled) {
        const result = {
            destination: getWixappsDestination(),
            name: 'wixapps batch',
            url: `${urlUtils.baseUrl(siteData.getExternalBaseUrl())}/apps/lists/1/Batch?consistentRead=false`,
            data: {operations: getOperationsForBatchableRequests(batchableRequests)},
            transformFunc: _.partial(delegateTransformationToBatchableRequests, batchableRequests),
            ontimeout: _.partial(delegateErrorHandlerToBatchableRequests, batchableRequests, 'ontimeout'),
            error: _.partial(delegateErrorHandlerToBatchableRequests, batchableRequests, 'error'),
            force: true
        };
        const ts = _.map(batchableRequests, 'timeout');
        if (!isTimeoutDisabled) {
            result.timeout = _.max(ts) || 10;
        }
        return result;
    }

    function getOperationsForBatchableRequests(batchableRequests) {
        return _.map(batchableRequests, function (batchableRequest) {
            return {
                name: getBatchableOperationNameFromUrl(batchableRequest.url),
                params: batchableRequest.data
            };
        });
    }

    function getBatchableOperationNameFromUrl(url) {
        const pathname = getPathnameFromUrl(url);
        const operationNameInPathnameRegExp = getRegExpForMatchingBatchableOperationNameInPathname();
        const matches = pathname.match(operationNameInPathnameRegExp);
        return _.get(matches, 1);
    }

    function getPathnameFromUrl(url) {
        return urlUtils.parseUrl(url).path;
    }

    function getRegExpForMatchingBatchableOperationNameInPathname() {
        return /^\/apps\/lists\/1\/(GroupByAndCount|Query|ReadItem)$/;
    }

    function delegateTransformationToBatchableRequests(batchableRequests, batchResponseData, wixappsInSiteData) {
        _.forEach(batchableRequests, function (batchableRequest, index) {
            const responseData = batchResponseData.payload.results[index];
            const transformValue = _.partial(batchableRequest.transformFunc, responseData);
            const destinationInWixapps = _.difference(batchableRequest.destination, getWixappsDestination());
            transformObjectAtPath(wixappsInSiteData, destinationInWixapps, transformValue);
        });

        return wixappsInSiteData;
    }

    function delegateErrorHandlerToBatchableRequests(batchableRequests, handleName) {
        _.forEach(batchableRequests, function (batchableRequest) {
            _.invoke(batchableRequest, handleName);
        });
    }

    function getWixappsDestination() {
        return ['wixapps'];
    }

    function transformObjectAtPath(object, path, transformValue) {
        const currentValue = _.get(object, path);
        const transformedValue = transformValue(currentValue);
        _.set(object, path, transformedValue);
    }
});
