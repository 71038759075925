define(['coreUtils',
    'wixappsClassics/ecommerce/data/cartManager',
    'wixappsClassics/ecommerce/util/thankYouPageHandler',
    'wixappsClassics/ecommerce/util/ecomDataUtils',
    'experiment'
], function (coreUtils, cartManager, thankYouPageHandler, ecomDataUtils, experiment) {
    'use strict';

    const API_URL_SEGMENT = '/apps/ecommerce/api/checkout?';
    const MIGRATION_API_URL_SEGMENT = '/_api/wix-ecommerce-migration-web/apps/ecommerce/api/checkout?';

    function createCheckoutUrlParamsString(siteData, partDefinition, sendSiteId) {
        const storeId = ecomDataUtils.getApplicationDataStore(siteData).items.storeId;
        const cartId = cartManager.getCartId(siteData);

        const urlQueryObj = {
            storeId,
            cartId,
            metaSiteId: siteData.rendererModel.metaSiteId,
            svSession: siteData.getSvSession(),
            successURL: getSuccessURL(siteData, partDefinition),
            returnToURL: coreUtils.urlUtils.removeUrlParam(siteData.currentUrl.full, 'f_checkoutResult')
        };

        if (sendSiteId) {
            urlQueryObj.siteId = siteData.rendererModel.siteId;
        }

        return coreUtils.urlUtils.toQueryString(urlQueryObj);
    }

    function getSuccessURL(siteData, partDefinition) {
        const currentUrl = thankYouPageHandler.getThankYouPageUrl(siteData, partDefinition) || siteData.currentUrl.full;
        return coreUtils.urlUtils.removeUrlParam(currentUrl, 'f_checkoutResult');
    }

    /**
     * Gateways such as Skrill, WebMoney, Authorize.net, Tranzila, PayU and offline checkouts are handled via
     * a window handled by us (prefixed with safer-checkout.wix.....)
     */
    function getInternalHandledCheckoutUrl(siteData, partDefinition) {
        let url = siteData.serviceTopology.ecommerceCheckoutUrl;
        //fix - when url don't have '/' at the end
        if (!url.match('/$')) {
            url += '/';
        }
        url += 'payment?';
        url += createCheckoutUrlParamsString(siteData, partDefinition, true);
        return url;
    }

    /**
     * Gateways such as PayPal and PagSeguro need to checkout via an external url (can be opened in same
     * window or new tab)
     */
    function getExternalHandledCheckoutUrl(siteData, partDefinition) {
        const apiUrl = experiment.isOpen('ecommigrationviewer', siteData) ? MIGRATION_API_URL_SEGMENT : API_URL_SEGMENT;
        let url = coreUtils.urlUtils.baseUrl(siteData.getExternalBaseUrl()) + apiUrl;
        url += createCheckoutUrlParamsString(siteData, partDefinition, false);
        return url;
    }

    return {
        getInternalHandledCheckoutUrl,
        getExternalHandledCheckoutUrl,
        getSuccessURL
    };
});
