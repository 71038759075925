define(['wixappsClassics/ecommerce/components/optionInput'], function (optionInput) {
    'use strict';

    /**
     * @class components.MobileColorOption
     * @extends {optionInput}
     * @property {comp.properties} props
     */
    return {
        displayName: 'MobileColorOption',
        mixins: [optionInput],

        getSkinProperties() {
            const refData = {
                '': {
                    style: {
                        backgroundColor: this.props.compData.text
                    }
                }
            };

            // set a click callback only when the component is enabled.
            if (!this.props.compData.disabled) {
                refData[''].onClick = this.props.onClick;
                refData[''].style.cursor = 'pointer';
            }
            return refData;
        }
    };
});
