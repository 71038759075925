define([
    'lodash',
    'componentsCore',
    'prop-types',
    'coreUtils',
    'santa-core-utils',
    'santa-components',
    'wixappsClassics/ecommerce/components/colorOption',
    'wixappsClassics/ecommerce/components/mobileColorOption',
    'wixappsClassics/ecommerce/components/mobileTextOption',
    'wixappsClassics/ecommerce/components/textOption'
], function (
    _,
    componentsCore,
    PropTypes,
    coreUtils,
    coreUtilsLib,
    santaComponents,
    colorOption,
    mobileColorOption,
    mobileTextOption,
    textOption
) {
    'use strict';

    function selectItem(itemData, event, domID) {
        if (this.props.onSelectionChange) {
            event.type = 'selectionChanged';
            event.payload = itemData;
            this.props.onSelectionChange(event, domID);
        }
    }

    /**
     * @class components.SelectOptionsList
     * @extends {core.skinBasedComp}
     * @extends {coreUtilsLib.timersMixins.timeoutsMixin}
     * @property {{$validity: string, $tooltip: string}}} state
     */
    return {
        displayName: 'SelectOptionsList',
        mixins: [componentsCore.mixins.skinBasedComp, coreUtilsLib.timersMixins.timeoutsMixin, componentsCore.mixins.createChildComponentMixin],

        propType: _.defaults({
            itemClassName: PropTypes.string.isRequired,
            itemSkin: PropTypes.string.isRequired,
            selectedItem: PropTypes.object,
            valid: PropTypes.boolean,

            // Events
            onSelectionChange: PropTypes.func
        },
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(colorOption),
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(mobileColorOption),
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(mobileTextOption),
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(textOption)
        ),

        getInitialState() {
            return {
                '$validity': this.props.valid === false ? 'invalid' : 'valid',
                '$tooltip': this.props.selectedItem && this.props.selectedItem.description ? 'displayed' : 'hidden'
            };
        },

        componentWillMount() {
            if (this.state.$tooltip === 'displayed') {
                const self = this;
                this.setTimeout(function () {
                    self.setState({$tooltip: 'hidden'});
                }, 1500);
            }
        },

        componentWillReceiveProps(props) { // eslint-disable-line complexity
            const oldProps = this.props;
            const newState = {
                $validity: props.valid === false ? 'invalid' : 'valid'
            };

            if (!oldProps.selectedItem || oldProps.selectedItem && props.selectedItem && oldProps.selectedItem.description !== props.selectedItem.description) { // eslint-disable-line no-mixed-operators
                newState.$tooltip = props.selectedItem && props.selectedItem.description ? 'displayed' : 'hidden';
            }

            this.setState(newState);
            if (this.props.selectedItem !== props.selectedItem && newState.$tooltip === 'displayed') {
                const self = this;
                this.setTimeout(function () {
                    self.setState({$tooltip: 'hidden'});
                }, 1500);
            }
        },

        getSkinProperties() {
            const children = _.map(this.props.compData.options, function (itemData, index) {
                const extraProperties = {
                    selected: _.isEqual(this.props.selectedItem, itemData),
                    onClick: selectItem.bind(this, itemData),
                    ref: index
                };

                return this.createChildComponent(
                    itemData,
                    this.props.itemClassName, { //TODO: this should be in skin exports
                        skin: this.props.itemSkin,
                        styleId: coreUtils.santaTypes.shortenStyleId(this.props.itemSkin)
                    },
                    extraProperties);
            }.bind(this));

            const skinPartsProps = {
                itemsContainer: {
                    children
                }
            };

            if (this.props.selectedItem) {
                skinPartsProps.tooltip = {
                    children: [this.props.selectedItem.description]
                };
            }

            return skinPartsProps;
        }
    };
});
