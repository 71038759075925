define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';

    /**
     * @type {!Object.<!Object>}
     */
    const viewDefCacheByComponentId = {};
    coreUtils.sessionFreezer.freeze(viewDefCacheByComponentId);


    /**
     * @param {string} view
     * @param {string} type
     * @param {string} format
     * @returns {string}
     */
    function getViewDefKey(view, type, format) {
        return [view, type, format].join();
    }


    return {

        /**
         * @param {string} componentId
         * @param {string} view
         * @param {string} type
         * @param {string} format
         * @param {!Object} viewDef
         */
        setComponentViewDef(componentId, view, type, format, viewDef) {
            const viewDefKey = getViewDefKey(view, type, format);
            _.set(viewDefCacheByComponentId, [componentId, viewDefKey], viewDef);
        },


        /**
         * @param {string} componentId
         * @param {string} view
         * @param {string} type
         * @param {string} format
         * @returns {!Object|undefined}
         */
        getComponentViewDef(componentId, view, type, format) {
            const viewDefKey = getViewDefKey(view, type, format);
            return _.get(viewDefCacheByComponentId, [componentId, viewDefKey]);
        },


        /**
         * @param {string} componentId
         */
        removeComponentViewDefs(componentId) {
            delete viewDefCacheByComponentId[componentId];
        }

    };
});
