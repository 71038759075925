define([
    'lodash',
    'coreUtils',
    'prop-types',
    'componentsCore',
    'wixappsCore',
    'wixappsClassics/core/appPartStyleCollector',
    'wixappsClassics/util/appPartCommonDataManager',
    'wixappsClassics/util/wixappsUrlUtils',
    'wixappsClassics/util/viewCacheUtils',
    'wixappsClassics/util/descriptorUtils',
    'wixappsClassics/util/fontUtils',
    'wixappsClassics/blog/utils',
    'santa-components'
], function (
    _,
    coreUtils,
    PropTypes,
    componentsCore,
    /** wixappsCore */ wixapps,
    appPartStyleCollector,
    appPartCommonDataManager,
    appsUrlUtils,
    viewCacheUtils,
    descriptorUtils,
    fontUtils,
    blogUtils,
    santaComponents
) {
    'use strict';

    const DISPLAY_MODE = {
        LOADING: 'loading',
        CONTENT: 'content',
        ERROR: 'error'
    };

    const APP_PART_PLEASE_RELOAD_KEY = 'blogTranslations_please_reload';

    const wixappsClassicsLogger = coreUtils.wixappsClassicsLogger;
    const viewsCustomizer = wixapps.viewsCustomizer;

    const blogHashToName = Object.freeze(_.invert(coreUtils.blogAppPartNames));

    function reportPerformanceBi() {
        if (this.props.isViewerMode) {
            this.props.reportEvent(
                wixappsClassicsLogger.events.APP_PART_RENDERED,
                {
                    component_id: this.props.compData.id,
                    app_part_name: this.appPartName,
                    duration: _.now() - this.willRenderTime,
                    app_part_state: this.state.$displayMode,
                    occurrence_count: this.renderCount,
                    site_id: this.props.siteId
                }
            );
        }
        this.renderCount++;
    }

    function reportFirstTimeRenderFinish(appPartName, displayMode, willMountTime, willRenderTime) {
        if (displayMode !== DISPLAY_MODE.LOADING) {
            this.props.reportFirstTimeRenderFinish(
                this.props.compData.id,
                appPartName,
                displayMode,
                _.now() - willMountTime,
                _.now() - willRenderTime
            );
        }
    }

    function getTypeData(types, typeName) {
        return _.find(types, {_iid: typeName});
    }

    /**
     * Runs recursively and returns an array of the type, and all type it inherits from
     * @param types
     * @param typeName
     * @returns {Array} an array of type names I.E [MediaPost, Post]
     */
    function getTypesFallbacks(types, typeName) {
        const typeData = getTypeData(types, typeName);
        if (!typeData) {
            return [typeName];
        }
        const baseTypes = _.flattenDeep(_.map(typeData.baseTypes, function (baseTypeName) {
            return getTypesFallbacks(types, baseTypeName);
        }));
        return [typeName].concat(baseTypes);
    }

    function refreshPart() {
        this.registerReLayout();
        this.props.forceUpdate();
    }

    const {utils: santaUtils} = santaComponents;
    const SantaTypes = santaComponents.santaTypesDefinitions;
    const getCustomCss = wixapps.appPartCustomCss.getCustomCss(appPartStyleCollector);

    const partDataLocationType = Object.freeze(PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
        PropTypes.arrayOf(PropTypes.string)
    ]));

    const wixappsClassicsPrivateSantaTypes = santaUtils.createSantaTypesDefinitions({
        Zoom: {
            getAppPartZoomAdditionalDataPart: PropTypes.func,
            pageSubItemId: PropTypes.string
        },
        reportEvent: PropTypes.func,
        reportError: PropTypes.func,
        reportAppPartMetadataError: PropTypes.func,
        reportFirstTimeRenderStart: PropTypes.func,
        reportFirstTimeRenderFinish: PropTypes.func,
        partDefinition: PropTypes.object,
        partDataLocation: partDataLocationType,
        partData: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object, PropTypes.string]),
        blogStoreId: PropTypes.object,
        blogAppPage: PropTypes.object,
        compMetadata: PropTypes.object,
        didCompRequestFailedInSSR: PropTypes.bool,
        updatePageTitleAndMetaTags: PropTypes.func,
        isSelectionSharerVisible: PropTypes.bool,
        getCurrentUrl: PropTypes.func,
        showSelectionSharer: PropTypes.func,
        hideSelectionSharer: PropTypes.func,
        setCustomUrlMapping: PropTypes.func,
        refreshRenderedRootsData: PropTypes.func
    }, 'wixapssClassicsSantaTypes');

    const fontsTypes = {
        compData: SantaTypes.Component.compData,
        partData: wixappsClassicsPrivateSantaTypes.partData,
        descriptor: wixapps.CoreSantaTypes.descriptor
    };

    const getCustomCompFonts = ({compData, partData, descriptor}) => _.union(fontUtils.getFontFamiliesFromTextData(partData), fontUtils.getCustomFontFamilies(descriptor, compData));

    getCustomCompFonts.fontsTypes = fontsTypes;

    const ecomPrivateSantaTypesDefs = santaUtils.createSantaTypesDefinitions({
        checkoutUrl: PropTypes.string,
        cartPageData: PropTypes.object,
        cartId: PropTypes.string,
        thankYouPageUrl: PropTypes.string,
        productIdFromUrl: PropTypes.string,
        hasPremium: PropTypes.bool,
        reportError: PropTypes.func,
        reportEvent: PropTypes.func,
        isEcomTpaExistsAndEcomListNot: PropTypes.bool,
        ecomTpaProductPageId: PropTypes.string,
        showCheckoutDialogForOwner: PropTypes.func,
        showModal: PropTypes.func,
        showMessage: PropTypes.func,
        cart: PropTypes.shape({items: PropTypes.arrayOf(PropTypes.object.isRequired)}),

        CartManager: {
            setCartItemDataAndUpdate: PropTypes.func,
            setCoupon: PropTypes.func,
            clearCoupon: PropTypes.func,
            getShipping: PropTypes.func,
            setShipping: PropTypes.func,
            updateProduct: PropTypes.func,
            addProduct: PropTypes.func,
            removeProduct: PropTypes.func
        }
    }, 'ecommerceSantaTypes');

    /**
     * @class components.AppPart
     * @extends {core.skinBasedComp}
     * @extends {wixapps.viewsRenderer}
     */
    return {
        displayName: 'AppPart',
        mixins: [wixapps.viewsRenderer, santaComponents.mixins.runTimeCompData, componentsCore.mixins.customSkinBasedComp({customCssFunc: getCustomCss, customFontsFunc: getCustomCompFonts})],
        statics: {
            santaTypeDefinitions: {wixappsClassics: wixappsClassicsPrivateSantaTypes, ecom: ecomPrivateSantaTypesDefs}
        },
        propTypes: {
            isMobileView: SantaTypes.isMobileView.isRequired,
            isMobileDevice: SantaTypes.Device.isMobileDevice.isRequired,
            compData: SantaTypes.Component.compData.isRequired,
            getMemberDetails: SantaTypes.SiteMembersSantaTypes.getMemberDetails.isRequired,
            compExtraData: wixapps.CoreSantaTypes.compExtraData,
            forceUpdate: wixapps.CoreSantaTypes.__DangerousSantaTypes.forceUpdate.isRequired,
            formatName: wixapps.CoreSantaTypes.formatName.isRequired,
            appService: wixapps.CoreSantaTypes.appService.isRequired,
            packageName: wixapps.CoreSantaTypes.packageName.isRequired,
            partDataLocation: wixappsClassicsPrivateSantaTypes.partDataLocation,
            partData: wixappsClassicsPrivateSantaTypes.partData,
            descriptor: wixapps.CoreSantaTypes.descriptor,
            partDefinition: wixappsClassicsPrivateSantaTypes.partDefinition,
            localizationBundle: wixapps.CoreSantaTypes.localizationBundle,
            reportFirstTimeRenderStart: wixappsClassicsPrivateSantaTypes.reportFirstTimeRenderStart.isRequired,
            reportFirstTimeRenderFinish: wixappsClassicsPrivateSantaTypes.reportFirstTimeRenderFinish.isRequired,
            reportEvent: wixappsClassicsPrivateSantaTypes.reportEvent.isRequired,
            reportError: wixappsClassicsPrivateSantaTypes.reportError.isRequired,
            reportAppPartMetadataError: wixappsClassicsPrivateSantaTypes.reportAppPartMetadataError.isRequired,
            appPageParams: wixapps.CoreSantaTypes.appPageParams,
            currentUrl: SantaTypes.currentUrl.isRequired,
            cookie: SantaTypes.RequestModel.cookie,
            siteId: SantaTypes.RendererModel.siteId.isRequired,
            userId: SantaTypes.RendererModel.userId.isRequired,
            isViewerMode: SantaTypes.isViewerMode.isRequired,
            compMetadata: wixappsClassicsPrivateSantaTypes.compMetadata.isRequired,
            didCompRequestFailedInSSR: wixappsClassicsPrivateSantaTypes.didCompRequestFailedInSSR.isRequired,
            packageMetadata: wixapps.CoreSantaTypes.packageMetadata.isRequired,
            getCurrentUrl: wixappsClassicsPrivateSantaTypes.getCurrentUrl.isRequired,
            Logic: wixapps.CoreSantaTypes.Logic,
            isExperimentOpen: SantaTypes.isExperimentOpen,
            userLanguage: SantaTypes.WixUserSantaTypes.userLanguage.isRequired,

            // Logic props
            rootId: SantaTypes.Component.rootId,
            rootNavigationInfo: SantaTypes.Component.rootNavigationInfo.isRequired,
            getMediaFullStaticUrl: SantaTypes.ServiceTopology.getMediaFullStaticUrl,
            updatePageTitleAndMetaTags: wixappsClassicsPrivateSantaTypes.updatePageTitleAndMetaTags.isRequired,
            showSelectionSharer: wixappsClassicsPrivateSantaTypes.showSelectionSharer.isRequired,
            hideSelectionSharer: wixappsClassicsPrivateSantaTypes.hideSelectionSharer.isRequired,
            isSelectionSharerVisible: wixappsClassicsPrivateSantaTypes.isSelectionSharerVisible.isRequired,
            setCustomUrlMapping: wixappsClassicsPrivateSantaTypes.setCustomUrlMapping.isRequired,
            getDataByPath: wixapps.CoreSantaTypes.Data.getDataByPath.isRequired,
            setBatchedData: wixapps.CoreSantaTypes.Data.setBatchedData.isRequired,
            metaSiteId: SantaTypes.RendererModel.metaSiteId.isRequired,
            documentType: SantaTypes.RendererModel.documentType.isRequired,
            siteTitleSEO: SantaTypes.RendererModel.siteTitleSEO,
            navigateToPage: SantaTypes.navigateToPage.isRequired,
            addComponent: SantaTypes.AspectComponent.addComponent.isRequired,
            deleteComponent: SantaTypes.AspectComponent.deleteComponent.isRequired,
            handleShareRequest: SantaTypes.SocialShareHandler.handleShareRequest.isRequired,
            setShipping: ecomPrivateSantaTypesDefs.CartManager.setShipping.isRequired,
            getShipping: ecomPrivateSantaTypesDefs.CartManager.getShipping.isRequired,
            clearCoupon: ecomPrivateSantaTypesDefs.CartManager.clearCoupon.isRequired,
            setCoupon: ecomPrivateSantaTypesDefs.CartManager.setCoupon.isRequired,
            setCartItemDataAndUpdate: ecomPrivateSantaTypesDefs.CartManager.setCartItemDataAndUpdate.isRequired,
            updateProduct: ecomPrivateSantaTypesDefs.CartManager.updateProduct.isRequired,
            removeProduct: ecomPrivateSantaTypesDefs.CartManager.removeProduct.isRequired,
            addProduct: ecomPrivateSantaTypesDefs.CartManager.addProduct.isRequired,
            hasPremiumEcom: ecomPrivateSantaTypesDefs.hasPremium,
            cartId: ecomPrivateSantaTypesDefs.cartId,
            cartPageData: ecomPrivateSantaTypesDefs.cartPageData,
            cart: ecomPrivateSantaTypesDefs.cart,
            isEcomTpaExistsAndEcomListNot: ecomPrivateSantaTypesDefs.isEcomTpaExistsAndEcomListNot,
            productIdFromUrl: ecomPrivateSantaTypesDefs.productIdFromUrl,
            ecomTpaProductPageId: ecomPrivateSantaTypesDefs.ecomTpaProductPageId,
            thankYouPageUrl: ecomPrivateSantaTypesDefs.thankYouPageUrl,
            checkoutUrl: ecomPrivateSantaTypesDefs.checkoutUrl,
            showMessage: ecomPrivateSantaTypesDefs.showMessage.isRequired,
            showModal: ecomPrivateSantaTypesDefs.showModal.isRequired,
            showCheckoutDialogForOwner: ecomPrivateSantaTypesDefs.showCheckoutDialogForOwner.isRequired,
            ecomReportEvent: ecomPrivateSantaTypesDefs.reportEvent.isRequired,
            ecomReportError: ecomPrivateSantaTypesDefs.reportError.isRequired,
            currentUrlPageId: SantaTypes.currentUrlPageId.isRequired,
            currentUrlPageTitle: SantaTypes.Component.currentUrlPageTitle.isRequired,
            blogAppPage: wixappsClassicsPrivateSantaTypes.blogAppPage,
            refreshRenderedRootsData: wixappsClassicsPrivateSantaTypes.refreshRenderedRootsData.isRequired,
            isInSSR: SantaTypes.isInSSR.isRequired,
            isFirstRenderAfterSSR: SantaTypes.isFirstRenderAfterSSR.isRequired,

            // Proxy props
            blogStoreId: wixappsClassicsPrivateSantaTypes.blogStoreId,
            urlFormat: SantaTypes.urlFormat.isRequired
        },
        getInitialState() {
            this.partApi = this.getPartApi();
            this.errorReported = false;
            if (this.props.Logic) {
                this.logic = new this.props.Logic(this.partApi);
            }

            return this.getState();
        },

        componentWillMount() {
            this.appPartName = blogHashToName[this.props.compData.appPartName];
            this.renderCount = 0;
            this.willRenderTime = _.now();
            this.willMountTime = _.now();

            this.props.reportFirstTimeRenderStart(this.props.compData.id, this.appPartName);
        },
        componentDidMount() {
            reportFirstTimeRenderFinish.call(this,
                this.appPartName,
                this.state.$displayMode,
                this.willMountTime,
                this.willRenderTime
            );

            reportPerformanceBi.call(this);
            this.willRenderTime = null;
        },
        componentWillUpdate() {
            this.willRenderTime = _.now();
        },
        componentDidUpdate() {
            reportFirstTimeRenderFinish.call(this,
                this.appPartName,
                this.state.$displayMode,
                this.willMountTime,
                this.willRenderTime
            );

            reportPerformanceBi.call(this);
            this.willRenderTime = null;
        },

        componentWillReceiveProps(newProps) {
            if (newProps.compData.appLogicCustomizations !== this.props.compData.appLogicCustomizations) {
                viewCacheUtils.removeComponentViewDefs(this.props.id);
            }
            const state = this.getState(newProps);
            if (state.$displayMode !== this.state.$displayMode) {
                this.setState(state);
            }
        },

        getViewProps() {
            return {
                blogStoreId: this.props.blogStoreId,
                partData: this.props.partData
            };
        },

        getState(props) { // eslint-disable-line complexity
            props = props || this.props;
            this.partApi.setProps(props);
            if (props.packageMetadata.removed) {
                return {$displayMode: DISPLAY_MODE.ERROR, error: true};
            }

            const metadata = props.compMetadata;
            if (metadata.hasError) {
                if (!this.errorReported) {
                    this.props.reportAppPartMetadataError();
                    this.errorReported = true;
                }
                return {$displayMode: DISPLAY_MODE.ERROR, error: true};
            }

            const logicHasIsReadyFunc = this.logic && this.logic.isReady;
            const isReady = !logicHasIsReadyFunc || this.logic.isReady(props);

            if (isReady && logicHasIsReadyFunc && _.isFunction(this.logic.isReadySideEffects)) {
                this.logic.isReadySideEffects(props);
            }

            const loading = metadata.loading || metadata.videos > 0 || !props.partData || !props.descriptor || props.didCompRequestFailedInSSR && props.isFirstRenderAfterSSR; // eslint-disable-line no-mixed-operators
            const state = {
                $displayMode: loading || !isReady ? DISPLAY_MODE.LOADING : DISPLAY_MODE.CONTENT,
                loading
            };
            if (this.state && this.state.$displayMode && state.$displayMode !== this.state.$displayMode) {
                this.registerReLayout();
            }

            return state;
        },

        getViewDef(viewName, typeName) { // eslint-disable-line complexity
            const descriptor = this.props.descriptor;
            const types = getTypesFallbacks(descriptor.types, typeName);

            const componentId = this.props.id;

            const formatName = this.props.formatName;
            let viewDef = viewCacheUtils.getComponentViewDef(componentId, viewName, typeName, formatName);
            if (viewDef) {
                return viewDef;
            }

            let view;
            while (!view && types.length) {
                view = wixapps[this.props.isExperimentOpen('wixappsViewsCaching') ? 'memoizedViewsUtils' : 'viewsUtils'].findViewInDescriptorByNameTypeAndFormat(descriptor, viewName, types.shift(), formatName);
            }

            if (!view) {
                return null;
            }

            viewDef = _.cloneDeep(view);

            viewDef.name = viewName;

            if (formatName && // The only truthy value is "Mobile".
                coreUtils.appPartMediaInnerViewNameUtils.isMediaInnerViewName(viewName)) {
                // For an inner view set mobile format explicitly.
                // Otherwise, mobile customizations aren't applied if format is undefined.
                // See the commit description for more details.
                viewDef.format = formatName;
            }

            viewDef = viewsCustomizer.customizeView(viewDef, this.getAppCustomizations(), this.getUserCustomizations());

            viewCacheUtils.setComponentViewDef(componentId, viewName, typeName, formatName, viewDef);

            return viewDef;
        },

        componentWillUnmount() {
            const componentId = this.props.id;
            //var logicHasBeforeCloseFunc = (this.logic && this.logic.beforeClose);
            if (this.logic && this.logic.beforeClose) {
                this.logic.beforeClose();
            }
            viewCacheUtils.removeComponentViewDefs(componentId);
            appPartCommonDataManager.removeAppPartCommonData(this.props.compData.id);
        },

        getViewName() {
            const viewName = this.props.compData.viewName;
            return wixapps.proxyFactory.isValidProxyName(viewName) ? `${viewName}View` : viewName;
        },

        getUserCustomizations() {
            let customizations;
            if (this.logic && this.logic.getUserCustomizations) {
                customizations = this.logic.getUserCustomizations(this.props.compData.appLogicCustomizations);
            } else {
                customizations = this.props.compData.appLogicCustomizations;
            }
            return customizations;
        },

        getAppCustomizations() {
            return this.props.descriptor.customizations;
        },

        getLayoutRootProxy() {
            return this.refs.rootProxy && this.refs.rootProxy.refs.child;
        },

        isLayoutRootProxyReady() {
            return !_.isUndefined(this.getLayoutRootProxy());
        },

        getPartApi() {
            /**
             * @class wixappsClassics.appPartApi
             */
            let props = this.props;
            return {
                setProps(nextProps) { props = nextProps; },
                reportEvent() { return props.reportEvent.apply(null, arguments); },
                getRootNavigationInfo() { return props.rootNavigationInfo; },
                getRootId() { return props.rootId; },
                getCompId() { return props.id; },
                getFormatName() { return props.formatName; },
                getPackageMetadata() { return props.packageMetadata; },
                getPartData() { return props.compData; },
                getPreviewTooltipCallback() { return props.previewTooltipCallback; },
                updatePageTitleAndMetaTags() { return props.updatePageTitleAndMetaTags.apply(null, arguments); },
                getMediaFullStaticUrl() { return props.getMediaFullStaticUrl.apply(null, arguments); },
                showSelectionSharer() { return props.showSelectionSharer.apply(null, arguments); },
                hideSelectionSharer() { return props.hideSelectionSharer.apply(null, arguments); },
                isSelectionSharerVisible() { return props.isSelectionSharerVisible; },
                setCustomUrlMapping() { return props.setCustomUrlMapping.apply(null, arguments); },
                getMetaSiteId() { return props.metaSiteId; },
                getSiteId() { return props.siteId; },
                getCartId() { return props.cartId; },
                getCartPageData() { return props.cartPageData; },
                addComponent() { return props.addComponent.apply(null, arguments); },
                navigateToPage() { return !props.isInSSR && props.navigateToPage.apply(null, arguments); },
                getEcomCart() { return props.cart; },
                hasPremiumEcom() { return props.hasPremiumEcom; },
                setShipping() { return props.setShipping.apply(null, arguments); },
                getShipping() { return props.getShipping.apply(null, arguments); },
                clearCoupon() { return props.clearCoupon.apply(null, arguments); },
                setCoupon() { return props.setCoupon.apply(null, arguments); },
                updateProduct() { return props.updateProduct.apply(null, arguments); },
                removeProduct() { return props.removeProduct.apply(null, arguments); },
                addProduct() { return props.addProduct.apply(null, arguments); },
                setCartItemDataAndUpdate() { return props.setCartItemDataAndUpdate.apply(null, arguments); },
                isEcomTpaExistsAndEcomListNot() { return props.isEcomTpaExistsAndEcomListNot; },
                handleShareRequest() { return props.handleShareRequest.apply(null, arguments); },
                getUrlFormat() { return props.urlFormat; },
                getCurrentUrl() { return props.getCurrentUrl(); },
                getCurrentUrlObj() { return props.currentUrl; },
                getCurrentUrlInFormat(format) { return props.getCurrentUrl(format, undefined, props.urlFormat !== format); },
                getCurrentUrlPageId() { return props.currentUrlPageId; },
                isExperimentOpen() { return props.isExperimentOpen; },
                getCurrentUrlPageTitle() { return props.currentUrlPageTitle; },
                getBlogAppPage() { return props.blogAppPage; },
                refreshRenderedRootsData() { return props.refreshRenderedRootsData.apply(null, arguments); },
                getProductIdFromUrl() { return props.productIdFromUrl; },
                getEcomTpaProductPageId() { return props.ecomTpaProductPageId; },
                getThankYouPageUrl() { return props.thankYouPageUrl; },
                getCheckoutUrl() { return props.checkoutUrl; },
                isInTemplatePublicViewer() { return props.documentType === 'Template'; },
                getSiteTitleSEO() { return props.siteTitleSEO; },
                ecomShowModal() { return props.showModal.apply(null, arguments); },
                ecomShowMessage() { return props.showMessage.apply(null, arguments); },
                ecomShowCheckoutDialogForOwner() { return props.showCheckoutDialogForOwner.apply(null, arguments); },
                getMemberDetails() { return props.getMemberDetails.apply(null, arguments); },
                deleteComponent() { return props.deleteComponent.apply(null, arguments); },
                getCompExtraData() { return props.compExtraData; },
                getAppPageParams() { return props.appPageParams; },
                ecomReportEvent() { return props.ecomReportEvent.apply(null, arguments); },
                ecomReportError() { return props.ecomReportError.apply(null, arguments); },
                isMobileView() { return props.isMobileView; },
                isMobileDevice() { return props.isMobileDevice; },
                getDataByPath() { return props.getDataByPath.apply(null, arguments); },
                setBatchedData() { return props.setBatchedData.apply(null, arguments); },
                getAppPartDefinition() { return props.partDefinition; },
                getLocalizationBundle() { return props.localizationBundle; },
                getPackageName() { return props.packageName; },
                refreshPart: refreshPart.bind(this),
                getRootDataItemRef() { return props.partDataLocation; },
                resolveImageData: this.resolveImageData,
                getAppService() { return props.appService; },
                isLayoutRootProxyReady: this.isLayoutRootProxyReady,
                setVar: function (name, value) {
                    this.setVar(this.refs.rootProxy.contextPath, name, value);
                }.bind(this),
                setVarOfLayoutRootProxy: function (name, value) {
                    this.getLayoutRootProxy().setVar(name, value);
                }.bind(this),
                setCssState: function (state) {
                    this.setState({$displayMode: state});
                }.bind(this),
                isInSSR() { return props.isInSSR; }
            };
        },

        resolveImageData(data) {
            return appsUrlUtils.resolveImageData(data, this.props.serviceTopology, this.props.packageName);
        },

        isHeightResizable() {
            if (this.logic && this.logic.isHeightResizable) {
                return this.logic.isHeightResizable();
            }

            return descriptorUtils.doesAllowHeightResize(this.props.partDefinition, this.getViewName(), this.props.formatName);
        },

        getSkinProperties() {
            if (this.state.loading || this.state.error) {
                const lang = this.props.userLanguage;
                return {
                    '': {
                        style: this.props.style
                    },
                    error: {
                        children: [santaComponents.utils.createReactElement('div', null, blogUtils.translate(lang, APP_PART_PLEASE_RELOAD_KEY))]
                    }
                };
            }

            const allowHeightResize = this.isHeightResizable();

            const content = [
                this.renderView(allowHeightResize)
            ];

            const style = _.clone(this.props.style || {});
            if (!allowHeightResize) {
                style.height = 'auto';
            }

            return {
                '': {
                    style,
                    'data-dynamic-height': !allowHeightResize
                },
                inlineContent: {
                    children: content,
                    style: {
                        height: allowHeightResize ? '100%' : style.height,
                        width: '100%'
                    }
                }
            };
        }
    };
});
